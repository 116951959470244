import Accordion from 'accordion-js';

/**
 * Creates an instance of the BoltAccordion class.
 * @param {HTMLElement} el - The element to initialize the accordion on.
 */
export class BoltAccordion {
  constructor(el) {
    if (!el) return;
    this.el = el;
    this.init();
  }

  /**
   * Initializes the accordion functionality.
   * If the accordion is already initialized, update it instead.
   */
  init() {
    // If the accordion has not been initialized yet, initialize it.
    if (!this.el.hasAttribute('data-bolt-ready')) {
      let accordion;
      this.single = this.el.hasAttribute('data-bolt-single');
      // the following two constants are deprecated and should be removed in a future release.
      const urlParams = new URLSearchParams(window.location.search);
      const selectedItemParam = urlParams.get('selected-accordion-item');

      if (window.location.hash !== '') {
        // this is the preferred deep linking method.
        // note that the variable must be unset if unused for handleDeepLink() 
        this.deepLinkTarget = this.el.querySelector(
          `${window.location.hash}:not([data-bolt-inactive])`,
        );
      } else if (selectedItemParam) {
        // this section of the conditional as well as the two associated constants above
        // are deprecated and should be removed in a future release.
        this.deepLinkTarget = this.el.querySelector(
          `#${selectedItemParam}:not([data-bolt-inactive])`,
        );
        this.deepLinkTarget.scrollIntoView({
          behavior: 'smooth'
        })
      }

      // Run before setting this.openItems so we can leverage it and
      // this.getIndexofOpenItems().
      this.handleDeepLink();

      this.allItems = this.el.querySelectorAll('.c-bolt-accordion-item');
      this.deepLinkTriggers = this.getDeepLinkTriggers();
      this.openItems = this.el.querySelectorAll(
        '.js-bolt-accordion-item--open',
      );
      this.indexOfOpenItems = this.getIndexofOpenItems();

      // Use `js-bolt-accordion-item` as the elementClass by default.
      let elementClass = 'js-bolt-accordion-item';

      // Expect accordion items to be direct children in accordions.
      let onlyChildNodes = true;

      // If accordion was created in Layout Page, handle it differently.
      if (this.isLayoutPageAccordion()) {
        // Layout Page uses the contrib `layout_paragraphs` module, which wraps
        // accordion items in additional divs. To make accordions function
        // correctly, we set `onlyChildNodes` to `false`.
        onlyChildNodes = false;

        // Setting `onlyChildNodes` to `false` interferes with nested
        // accordions: Clicking a nested accordion trigger collapses the parent
        // item instead of just the nested one. To prevent this, we assign a
        // UUID to each Layout Page accordion and add it to `elementClass` for
        // targeted behavior.
        const uuid = this.el.getAttribute('uuid');
        elementClass += `-${uuid}`;
      }

      // Create a configuration object for the Accordion instance.
      const accordionConfig = {
        duration: 200,
        elementClass,
        triggerClass: 'js-bolt-accordion-trigger',
        panelClass: 'js-bolt-accordion-content',
        showMultiple: !this.single, // inverted because our prop works opposite to the library's.
        openOnInit: this.indexOfOpenItems,
        onlyChildNodes,
        onClose: (currElement) => {
          this.handleClose(currElement);
        },
      };

      // Check if editing/creating a Drupal node.
      if (this.isDrupalNodeEdit()) {
        // Store the Accordion object in the DOM so that it can be accessed
        // later by other Drupal admin JS.
        this.el.accordionInstance = new Accordion(this.el, accordionConfig);
      } else {
        // In all other situations, do not store the Accordion object in the
        // DOM.
        accordion = new Accordion(this.el, accordionConfig);
      }

      this.deepLinkTriggers.forEach((element, index) => {
        element.addEventListener('click', (e) => {
          const deepLinkTarget = this.el.querySelector(
            `${e.currentTarget.hash}:not([data-bolt-inactive])`,
          );

          if (this.isDrupalNodeEdit()) {
            this.el.accordionInstance.open(Array.from(this.allItems).indexOf(deepLinkTarget));
          } else {
            accordion.open(Array.from(this.allItems).indexOf(deepLinkTarget));
          }
        });
      });

      // Add data-bolt-ready attribute. We check for this before running the
      // init() function to ensure an accordion is not initialized more than
      // once.
      this.el.setAttribute('data-bolt-ready', '');
    }
    // If the accordion is already initialzied, do not initialize it again.
    else {
      // If an Accordion Item has been dynamically added inside of an Accordion
      // on a Drupal edit page.
      if (this.isDrupalNodeEdit() && this.el.hasAttribute('data-bolt-update')) {
        // Update the accordion and its items.
        this.el.accordionInstance.update();

        // Remove the temporary attribute.
        this.el.removeAttribute('data-bolt-update');
      }
    }
  }

  /**
   * Checks if the current context is a Drupal node edit or node create page.
   * @returns {boolean} True if in node edit context, otherwise false.
   */
  isDrupalNodeEdit() {
    // Drupal adds the class `path-node` to the `<body>` when you are editing
    // or creating a node.
    return document.body.classList.contains('path-node');
  }

  /**
   * Checks if accordion was created in Layout Page.
   *
   * Layout Page adds additional divs around the accordion item which causes
   * issues since our accordions are typically designed to have accordion items
   * be direct children in accordions.
   * We want to handle accordions created in Layout Page a little differently.
   */
  isLayoutPageAccordion() {
    if (this.el.hasAttribute('lp-accordion') && this.el.hasAttribute('uuid')) {
      return true;
    }
    return false;
  }

  /**
   * Handles deep linking to accordion items.
   * Removes any open items if in single mode and adds the target item to the open items.
   * Also manages smooth scrolling to the target item.
   */
  handleDeepLink() {
    if (!this.deepLinkTarget) {
      return;
    }

    if (this.single) {
      // When in "single" mode, a deep link should override any items set to auto-open.
      // Unset these items immediately or we face race conditions as Handorgel initializes.
      this.el
        .querySelectorAll('.js-bolt-accordion-item--open')
        .forEach((el) => el.classList.remove('js-bolt-accordion-item--open'));
    }

    // add deep link target to this.openItems to be handled in this.getIndexofOpenItems()
    this.deepLinkTarget.classList.add('js-bolt-accordion-item--open');
  }

  getDeepLinkTriggers() {
    let deepLinkTriggers = [];

    this.allItems.forEach((element, index) => {
      let currentTriggers = document.querySelectorAll(
        `[href="#${element.id}"]`,
      );

      if (currentTriggers.length > 0) {
        currentTriggers.forEach((element, index) => {
          deepLinkTriggers.push(element);
        });

      }
    });

    return deepLinkTriggers;
  }

  /**
   * Retrieves the indexes of the currently open accordion items.
   * @returns {Array<number>} An array of indexes representing open items.
   */
  getIndexofOpenItems() {
    let indexOfOpenItems = [];

    this.allItems.forEach((element, index) => {
      if (Array.from(this.allItems).indexOf(this.openItems[index]) !== -1) {
        indexOfOpenItems.push(
          Array.from(this.allItems).indexOf(this.openItems[index]),
        );
      }
    });

    return indexOfOpenItems;
  }

  /**
   * Handles the closing of an accordion panel.
   * Pauses any videos contained within the closed panel if they are not active.
   * @param {HTMLElement} el - The element that is being closed.
   */
  handleClose(el) {
    const videos = el.querySelectorAll('video-js');

    // check that the current panel contains a video, and that the panel is not active
    // onClose is called more than we want, making the is-active check necessary to preven unwanted pausing
    if (videos.length && !el.classList.contains('is-active')) {
      videos.forEach((video) => {
        videojs.getPlayer(video).pause();
      });
    }
  }
}
