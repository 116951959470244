export function getTallestStickyHeight() {
  const stickyElements = document.querySelectorAll('.js-bolt-sticky');

  let tallestStickyElement = 0;

  if (stickyElements.length) {
    stickyElements.forEach((el) => {
      const height = el.offsetHeight;
      if (height > tallestStickyElement) {
        tallestStickyElement = height;
      }
    });
  }

  return tallestStickyElement;
}
