import schema from '@bolt/elements-button/button.schema';
import { forwardRef } from 'react';
import classNames from 'classnames';
import { getSchemaPropClass } from '@bolt/react-utilities/helpers';

const Button = forwardRef(
  (
    {
      hierarchy,
      size,
      display,
      borderRadius,
      iconOnly,
      iconBefore,
      iconAfter,
      href,
      type,
      isLoading,
      children,
      className,
      ...otherProps
    },
    ref,
  ) => {
    const attributes = {};

    attributes.className = classNames(
      'e-bolt-button',
      className,
      getSchemaPropClass('hierarchy', hierarchy, schema, 'e-bolt-button--'),
      getSchemaPropClass('size', size, schema, 'e-bolt-button--'),
      getSchemaPropClass('display', display, schema, 'e-bolt-button--'),
      getSchemaPropClass(
        'border_radius',
        borderRadius,
        schema,
        'e-bolt-button--border-radius-',
      ),
      {
        'e-bolt-button--icon-only': iconOnly,
        'e-bolt-button--is-loading': isLoading,
      },
    );

    let TagName;

    if (href) {
      TagName = 'a';
      attributes.href = href;
    } else {
      TagName = 'button';
      attributes.type = type || 'button';
      attributes['aria-busy'] = isLoading;
    }

    return (
      <TagName {...attributes} {...otherProps} ref={ref}>
        {/* Backwards-compatible handling of iconOnly prop */}
        {iconOnly && typeof iconOnly === 'object' && (
          <span className="e-bolt-button__icon-center" aria-hidden="true">
            {iconOnly}
          </span>
        )}
        {iconBefore && (
          <span className="e-bolt-button__icon-before" aria-hidden="true">
            {iconBefore}
          </span>
        )}
        {children}
        {iconAfter && (
          <span className="e-bolt-button__icon-after" aria-hidden="true">
            {iconAfter}
          </span>
        )}
        {isLoading && (
          <span
            className="e-bolt-button__loading-spinner"
            aria-hidden="true"></span>
        )}
      </TagName>
    );
  },
);

export default Button;
