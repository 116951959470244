/**
 * Do not edit directly
 * Generated on Tue, 08 Apr 2025 13:33:57 GMT
 */

module.exports = {
  "alignments": {
    "start": {
      "value": "start",
      "filePath": "src/alignments.json",
      "isSource": true,
      "original": {
        "value": "start"
      },
      "name": "AlignmentsStart",
      "attributes": {
        "category": "alignments",
        "type": "start"
      },
      "path": [
        "alignments",
        "start"
      ]
    },
    "center": {
      "value": "center",
      "filePath": "src/alignments.json",
      "isSource": true,
      "original": {
        "value": "center"
      },
      "name": "AlignmentsCenter",
      "attributes": {
        "category": "alignments",
        "type": "center"
      },
      "path": [
        "alignments",
        "center"
      ]
    },
    "end": {
      "value": "end",
      "filePath": "src/alignments.json",
      "isSource": true,
      "original": {
        "value": "end"
      },
      "name": "AlignmentsEnd",
      "attributes": {
        "category": "alignments",
        "type": "end"
      },
      "path": [
        "alignments",
        "end"
      ]
    },
    "justify": {
      "value": "justify",
      "filePath": "src/alignments.json",
      "isSource": true,
      "original": {
        "value": "justify"
      },
      "name": "AlignmentsJustify",
      "attributes": {
        "category": "alignments",
        "type": "justify"
      },
      "path": [
        "alignments",
        "justify"
      ]
    }
  },
  "base-font-sizes": {
    "320px": {
      "value": "14px",
      "filePath": "src/base-font-sizes.json",
      "isSource": true,
      "original": {
        "value": "14px"
      },
      "name": "BaseFontSizes320px",
      "attributes": {
        "category": "base-font-sizes",
        "type": "320px"
      },
      "path": [
        "base-font-sizes",
        "320px"
      ]
    },
    "400px": {
      "value": "15px",
      "filePath": "src/base-font-sizes.json",
      "isSource": true,
      "original": {
        "value": "15px"
      },
      "name": "BaseFontSizes400px",
      "attributes": {
        "category": "base-font-sizes",
        "type": "400px"
      },
      "path": [
        "base-font-sizes",
        "400px"
      ]
    },
    "1400px": {
      "value": "16px",
      "filePath": "src/base-font-sizes.json",
      "isSource": true,
      "original": {
        "value": "16px"
      },
      "name": "BaseFontSizes1400px",
      "attributes": {
        "category": "base-font-sizes",
        "type": "1400px"
      },
      "path": [
        "base-font-sizes",
        "1400px"
      ]
    }
  },
  "border-radius": {
    "none": {
      "value": 0,
      "filePath": "src/border-radius.json",
      "isSource": true,
      "original": {
        "value": 0
      },
      "name": "BorderRadiusNone",
      "attributes": {
        "category": "border-radius",
        "type": "none"
      },
      "path": [
        "border-radius",
        "none"
      ]
    },
    "small": {
      "value": "3px",
      "filePath": "src/border-radius.json",
      "isSource": true,
      "original": {
        "value": "3px"
      },
      "name": "BorderRadiusSmall",
      "attributes": {
        "category": "border-radius",
        "type": "small"
      },
      "path": [
        "border-radius",
        "small"
      ]
    },
    "large": {
      "value": "0.75em",
      "filePath": "src/border-radius.json",
      "isSource": true,
      "original": {
        "value": "0.75em"
      },
      "name": "BorderRadiusLarge",
      "attributes": {
        "category": "border-radius",
        "type": "large"
      },
      "path": [
        "border-radius",
        "large"
      ]
    },
    "full": {
      "value": "100em",
      "filePath": "src/border-radius.json",
      "isSource": true,
      "original": {
        "value": "100em"
      },
      "name": "BorderRadiusFull",
      "attributes": {
        "category": "border-radius",
        "type": "full"
      },
      "path": [
        "border-radius",
        "full"
      ]
    }
  },
  "breakpoints": {
    "xxsmall": {
      "value": "320px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "320px"
      },
      "name": "BreakpointsXxsmall",
      "attributes": {
        "category": "breakpoints",
        "type": "xxsmall"
      },
      "path": [
        "breakpoints",
        "xxsmall"
      ]
    },
    "xsmall": {
      "value": "400px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "400px"
      },
      "name": "BreakpointsXsmall",
      "attributes": {
        "category": "breakpoints",
        "type": "xsmall"
      },
      "path": [
        "breakpoints",
        "xsmall"
      ]
    },
    "small": {
      "value": "600px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "600px"
      },
      "name": "BreakpointsSmall",
      "attributes": {
        "category": "breakpoints",
        "type": "small"
      },
      "path": [
        "breakpoints",
        "small"
      ]
    },
    "medium": {
      "value": "800px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "800px"
      },
      "name": "BreakpointsMedium",
      "attributes": {
        "category": "breakpoints",
        "type": "medium"
      },
      "path": [
        "breakpoints",
        "medium"
      ]
    },
    "large": {
      "value": "1000px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "1000px"
      },
      "name": "BreakpointsLarge",
      "attributes": {
        "category": "breakpoints",
        "type": "large"
      },
      "path": [
        "breakpoints",
        "large"
      ]
    },
    "xlarge": {
      "value": "1200px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "1200px"
      },
      "name": "BreakpointsXlarge",
      "attributes": {
        "category": "breakpoints",
        "type": "xlarge"
      },
      "path": [
        "breakpoints",
        "xlarge"
      ]
    },
    "xxlarge": {
      "value": "1400px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "1400px"
      },
      "name": "BreakpointsXxlarge",
      "attributes": {
        "category": "breakpoints",
        "type": "xxlarge"
      },
      "path": [
        "breakpoints",
        "xxlarge"
      ]
    },
    "xxxlarge": {
      "value": "1600px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "1600px"
      },
      "name": "BreakpointsXxxlarge",
      "attributes": {
        "category": "breakpoints",
        "type": "xxxlarge"
      },
      "path": [
        "breakpoints",
        "xxxlarge"
      ]
    },
    "xxxxlarge": {
      "value": "1920px",
      "filePath": "src/breakpoints.json",
      "isSource": true,
      "original": {
        "value": "1920px"
      },
      "name": "BreakpointsXxxxlarge",
      "attributes": {
        "category": "breakpoints",
        "type": "xxxxlarge"
      },
      "path": [
        "breakpoints",
        "xxxxlarge"
      ]
    }
  },
  "colors": {
    "brand": {
      "indigo": {
        "xdark": {
          "value": "rgb(9, 23, 52)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(9, 23, 52)"
          },
          "name": "ColorsBrandIndigoXdark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "indigo",
            "subitem": "xdark"
          },
          "path": [
            "colors",
            "brand",
            "indigo",
            "xdark"
          ]
        },
        "dark": {
          "value": "rgb(8, 28, 69)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(8, 28, 69)"
          },
          "name": "ColorsBrandIndigoDark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "indigo",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "brand",
            "indigo",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(0, 31, 95)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(0, 31, 95)"
          },
          "name": "ColorsBrandIndigoBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "indigo",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "indigo",
            "base"
          ]
        },
        "light": {
          "value": "rgb(62, 103, 187)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(62, 103, 187)"
          },
          "name": "ColorsBrandIndigoLight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "indigo",
            "subitem": "light"
          },
          "path": [
            "colors",
            "brand",
            "indigo",
            "light"
          ]
        },
        "xlight": {
          "value": "rgb(171, 194, 242)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(171, 194, 242)"
          },
          "name": "ColorsBrandIndigoXlight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "indigo",
            "subitem": "xlight"
          },
          "path": [
            "colors",
            "brand",
            "indigo",
            "xlight"
          ]
        }
      },
      "navy": {
        "xdark": {
          "value": "rgb(9, 23, 52)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(9, 23, 52)"
          },
          "name": "ColorsBrandNavyXdark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "navy",
            "subitem": "xdark"
          },
          "path": [
            "colors",
            "brand",
            "navy",
            "xdark"
          ]
        },
        "dark": {
          "value": "rgb(8, 28, 69)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(8, 28, 69)"
          },
          "name": "ColorsBrandNavyDark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "navy",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "brand",
            "navy",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(0, 31, 95)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(0, 31, 95)"
          },
          "name": "ColorsBrandNavyBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "navy",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "navy",
            "base"
          ]
        },
        "light": {
          "value": "rgb(62, 103, 187)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(62, 103, 187)"
          },
          "name": "ColorsBrandNavyLight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "navy",
            "subitem": "light"
          },
          "path": [
            "colors",
            "brand",
            "navy",
            "light"
          ]
        },
        "xlight": {
          "value": "rgb(171, 194, 242)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(171, 194, 242)"
          },
          "name": "ColorsBrandNavyXlight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "navy",
            "subitem": "xlight"
          },
          "path": [
            "colors",
            "brand",
            "navy",
            "xlight"
          ]
        }
      },
      "teal": {
        "xdark": {
          "value": "rgb(0, 81, 84)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(0, 81, 84)"
          },
          "name": "ColorsBrandTealXdark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "teal",
            "subitem": "xdark"
          },
          "path": [
            "colors",
            "brand",
            "teal",
            "xdark"
          ]
        },
        "dark": {
          "value": "rgb(10, 98, 102)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(10, 98, 102)"
          },
          "name": "ColorsBrandTealDark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "teal",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "brand",
            "teal",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(16, 165, 172)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(16, 165, 172)"
          },
          "name": "ColorsBrandTealBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "teal",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "teal",
            "base"
          ]
        },
        "light": {
          "value": "rgb(134, 202, 198)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(134, 202, 198)"
          },
          "name": "ColorsBrandTealLight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "teal",
            "subitem": "light"
          },
          "path": [
            "colors",
            "brand",
            "teal",
            "light"
          ]
        },
        "xlight": {
          "value": "rgb(183, 228, 230)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(183, 228, 230)"
          },
          "name": "ColorsBrandTealXlight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "teal",
            "subitem": "xlight"
          },
          "path": [
            "colors",
            "brand",
            "teal",
            "xlight"
          ]
        }
      },
      "orange": {
        "dark": {
          "value": "rgb(199, 66, 0)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(199, 66, 0)"
          },
          "name": "ColorsBrandOrangeDark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "orange",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "brand",
            "orange",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(247, 105, 35)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(247, 105, 35)"
          },
          "name": "ColorsBrandOrangeBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "orange",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "orange",
            "base"
          ]
        },
        "light": {
          "value": "rgb(255, 152, 102)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(255, 152, 102)"
          },
          "name": "ColorsBrandOrangeLight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "orange",
            "subitem": "light"
          },
          "path": [
            "colors",
            "brand",
            "orange",
            "light"
          ]
        }
      },
      "yellow": {
        "dark": {
          "value": "rgb(232, 175, 23)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(232, 175, 23)"
          },
          "name": "ColorsBrandYellowDark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "yellow",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "brand",
            "yellow",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(255, 200, 54)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(255, 200, 54)"
          },
          "name": "ColorsBrandYellowBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "yellow",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "yellow",
            "base"
          ]
        },
        "light": {
          "value": "rgb(255, 227, 153)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(255, 227, 153)"
          },
          "name": "ColorsBrandYellowLight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "yellow",
            "subitem": "light"
          },
          "path": [
            "colors",
            "brand",
            "yellow",
            "light"
          ]
        }
      },
      "wine": {
        "base": {
          "value": "rgb(102, 29, 52)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(102, 29, 52)"
          },
          "name": "ColorsBrandWineBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "wine",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "wine",
            "base"
          ]
        }
      },
      "pink": {
        "base": {
          "value": "rgb(230, 54, 144)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(230, 54, 144)"
          },
          "name": "ColorsBrandPinkBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "pink",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "pink",
            "base"
          ]
        }
      },
      "berry": {
        "base": {
          "value": "rgb(172, 19, 97)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(172, 19, 97)"
          },
          "name": "ColorsBrandBerryBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "berry",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "berry",
            "base"
          ]
        }
      },
      "violet": {
        "base": {
          "value": "rgb(95, 103, 185)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(95, 103, 185)"
          },
          "name": "ColorsBrandVioletBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "violet",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "violet",
            "base"
          ]
        }
      },
      "gray": {
        "xdark": {
          "value": "rgb(46, 46, 51)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(46, 46, 51)"
          },
          "name": "ColorsBrandGrayXdark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "gray",
            "subitem": "xdark"
          },
          "path": [
            "colors",
            "brand",
            "gray",
            "xdark"
          ]
        },
        "dark": {
          "value": "rgb(92, 93, 102)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(92, 93, 102)"
          },
          "name": "ColorsBrandGrayDark",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "gray",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "brand",
            "gray",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(141, 142, 153)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(141, 142, 153)"
          },
          "name": "ColorsBrandGrayBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "gray",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "gray",
            "base"
          ]
        },
        "light": {
          "value": "rgb(224, 226, 235)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(224, 226, 235)"
          },
          "name": "ColorsBrandGrayLight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "gray",
            "subitem": "light"
          },
          "path": [
            "colors",
            "brand",
            "gray",
            "light"
          ]
        },
        "xlight": {
          "value": "rgb(246, 246, 249)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(246, 246, 249)"
          },
          "name": "ColorsBrandGrayXlight",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "gray",
            "subitem": "xlight"
          },
          "path": [
            "colors",
            "brand",
            "gray",
            "xlight"
          ]
        }
      },
      "black": {
        "base": {
          "value": "rgb(21, 22, 25)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(21, 22, 25)"
          },
          "name": "ColorsBrandBlackBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "black",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "black",
            "base"
          ]
        }
      },
      "white": {
        "base": {
          "value": "rgb(255, 255, 255)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(255, 255, 255)"
          },
          "name": "ColorsBrandWhiteBase",
          "attributes": {
            "category": "colors",
            "type": "brand",
            "item": "white",
            "subitem": "base"
          },
          "path": [
            "colors",
            "brand",
            "white",
            "base"
          ]
        }
      }
    },
    "status": {
      "info": {
        "dark": {
          "value": "rgb(0, 72, 128)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(0, 72, 128)"
          },
          "name": "ColorsStatusInfoDark",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "info",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "status",
            "info",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(0, 118, 209)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(0, 118, 209)"
          },
          "name": "ColorsStatusInfoBase",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "info",
            "subitem": "base"
          },
          "path": [
            "colors",
            "status",
            "info",
            "base"
          ]
        },
        "light": {
          "value": "rgb(214, 237, 255)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(214, 237, 255)"
          },
          "name": "ColorsStatusInfoLight",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "info",
            "subitem": "light"
          },
          "path": [
            "colors",
            "status",
            "info",
            "light"
          ]
        }
      },
      "success": {
        "dark": {
          "value": "rgb(37, 105, 64)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(37, 105, 64)"
          },
          "name": "ColorsStatusSuccessDark",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "success",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "status",
            "success",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(53, 126, 56)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(53, 126, 56)"
          },
          "name": "ColorsStatusSuccessBase",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "success",
            "subitem": "base"
          },
          "path": [
            "colors",
            "status",
            "success",
            "base"
          ]
        },
        "light": {
          "value": "rgb(219, 240, 220)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(219, 240, 220)"
          },
          "name": "ColorsStatusSuccessLight",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "success",
            "subitem": "light"
          },
          "path": [
            "colors",
            "status",
            "success",
            "light"
          ]
        }
      },
      "error": {
        "dark": {
          "value": "rgb(157, 35, 21)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(157, 35, 21)"
          },
          "name": "ColorsStatusErrorDark",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "error",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "status",
            "error",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(180, 40, 24)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(180, 40, 24)"
          },
          "name": "ColorsStatusErrorBase",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "error",
            "subitem": "base"
          },
          "path": [
            "colors",
            "status",
            "error",
            "base"
          ]
        },
        "light": {
          "value": "rgb(250, 213, 209)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(250, 213, 209)"
          },
          "name": "ColorsStatusErrorLight",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "error",
            "subitem": "light"
          },
          "path": [
            "colors",
            "status",
            "error",
            "light"
          ]
        }
      },
      "warning": {
        "dark": {
          "value": "rgb(207, 179, 23)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(207, 179, 23)"
          },
          "name": "ColorsStatusWarningDark",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "warning",
            "subitem": "dark"
          },
          "path": [
            "colors",
            "status",
            "warning",
            "dark"
          ]
        },
        "base": {
          "value": "rgb(232, 205, 48)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(232, 205, 48)"
          },
          "name": "ColorsStatusWarningBase",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "warning",
            "subitem": "base"
          },
          "path": [
            "colors",
            "status",
            "warning",
            "base"
          ]
        },
        "light": {
          "value": "rgb(250, 244, 209)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(250, 244, 209)"
          },
          "name": "ColorsStatusWarningLight",
          "attributes": {
            "category": "colors",
            "type": "status",
            "item": "warning",
            "subitem": "light"
          },
          "path": [
            "colors",
            "status",
            "warning",
            "light"
          ]
        }
      }
    },
    "social": {
      "facebook": {
        "base": {
          "value": "rgb(58, 87, 156)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(58, 87, 156)"
          },
          "name": "ColorsSocialFacebookBase",
          "attributes": {
            "category": "colors",
            "type": "social",
            "item": "facebook",
            "subitem": "base"
          },
          "path": [
            "colors",
            "social",
            "facebook",
            "base"
          ]
        }
      },
      "x": {
        "base": {
          "value": "rgb(15, 20, 25)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(15, 20, 25)"
          },
          "name": "ColorsSocialXBase",
          "attributes": {
            "category": "colors",
            "type": "social",
            "item": "x",
            "subitem": "base"
          },
          "path": [
            "colors",
            "social",
            "x",
            "base"
          ]
        }
      },
      "linkedin": {
        "base": {
          "value": "rgb(4, 118, 180)",
          "filePath": "src/colors.json",
          "isSource": true,
          "original": {
            "value": "rgb(4, 118, 180)"
          },
          "name": "ColorsSocialLinkedinBase",
          "attributes": {
            "category": "colors",
            "type": "social",
            "item": "linkedin",
            "subitem": "base"
          },
          "path": [
            "colors",
            "social",
            "linkedin",
            "base"
          ]
        }
      }
    }
  },
  "display": {
    "block": {
      "value": "block",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "block"
      },
      "name": "DisplayBlock",
      "attributes": {
        "category": "display",
        "type": "block"
      },
      "path": [
        "display",
        "block"
      ]
    },
    "inlineBlock": {
      "value": "inline-block",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "inline-block"
      },
      "name": "DisplayInlineBlock",
      "attributes": {
        "category": "display",
        "type": "inlineBlock"
      },
      "path": [
        "display",
        "inlineBlock"
      ]
    },
    "inline": {
      "value": "inline",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "inline"
      },
      "name": "DisplayInline",
      "attributes": {
        "category": "display",
        "type": "inline"
      },
      "path": [
        "display",
        "inline"
      ]
    },
    "table": {
      "value": "table",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "table"
      },
      "name": "DisplayTable",
      "attributes": {
        "category": "display",
        "type": "table"
      },
      "path": [
        "display",
        "table"
      ]
    },
    "tableRow": {
      "value": "table-row",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "table-row"
      },
      "name": "DisplayTableRow",
      "attributes": {
        "category": "display",
        "type": "tableRow"
      },
      "path": [
        "display",
        "tableRow"
      ]
    },
    "tableCell": {
      "value": "table-cell",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "table-cell"
      },
      "name": "DisplayTableCell",
      "attributes": {
        "category": "display",
        "type": "tableCell"
      },
      "path": [
        "display",
        "tableCell"
      ]
    },
    "hidden": {
      "value": "none",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "none"
      },
      "name": "DisplayHidden",
      "attributes": {
        "category": "display",
        "type": "hidden"
      },
      "path": [
        "display",
        "hidden"
      ]
    },
    "flex": {
      "value": "flex",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "flex"
      },
      "name": "DisplayFlex",
      "attributes": {
        "category": "display",
        "type": "flex"
      },
      "path": [
        "display",
        "flex"
      ]
    },
    "inlineFlex": {
      "value": "inline-flex",
      "filePath": "src/display.json",
      "isSource": true,
      "original": {
        "value": "inline-flex"
      },
      "name": "DisplayInlineFlex",
      "attributes": {
        "category": "display",
        "type": "inlineFlex"
      },
      "path": [
        "display",
        "inlineFlex"
      ]
    }
  },
  "font-families": {
    "body": {
      "fallback-font": {
        "value": [
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesBodyFallbackFont",
        "attributes": {
          "category": "font-families",
          "type": "body",
          "item": "fallback-font"
        },
        "path": [
          "font-families",
          "body",
          "fallback-font"
        ]
      },
      "custom-font": {
        "value": [
          "Open Sans",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "Open Sans",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesBodyCustomFont",
        "attributes": {
          "category": "font-families",
          "type": "body",
          "item": "custom-font"
        },
        "path": [
          "font-families",
          "body",
          "custom-font"
        ]
      }
    },
    "bodySubset": {
      "fallback-font": {
        "value": [
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesBodySubsetFallbackFont",
        "attributes": {
          "category": "font-families",
          "type": "bodySubset",
          "item": "fallback-font"
        },
        "path": [
          "font-families",
          "bodySubset",
          "fallback-font"
        ]
      },
      "custom-font": {
        "value": [
          "OpenSansSubset",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "OpenSansSubset",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesBodySubsetCustomFont",
        "attributes": {
          "category": "font-families",
          "type": "bodySubset",
          "item": "custom-font"
        },
        "path": [
          "font-families",
          "bodySubset",
          "custom-font"
        ]
      }
    },
    "heading": {
      "fallback-font": {
        "value": [
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesHeadingFallbackFont",
        "attributes": {
          "category": "font-families",
          "type": "heading",
          "item": "fallback-font"
        },
        "path": [
          "font-families",
          "heading",
          "fallback-font"
        ]
      },
      "custom-font": {
        "value": [
          "Open Sans",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "Open Sans",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesHeadingCustomFont",
        "attributes": {
          "category": "font-families",
          "type": "heading",
          "item": "custom-font"
        },
        "path": [
          "font-families",
          "heading",
          "custom-font"
        ]
      }
    },
    "code": {
      "fallback-font": {
        "value": [
          "monospace",
          "monospace"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "monospace",
            "monospace"
          ]
        },
        "name": "FontFamiliesCodeFallbackFont",
        "attributes": {
          "category": "font-families",
          "type": "code",
          "item": "fallback-font"
        },
        "path": [
          "font-families",
          "code",
          "fallback-font"
        ]
      },
      "custom-font": {
        "value": [
          "monospace",
          "monospace"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "monospace",
            "monospace"
          ]
        },
        "name": "FontFamiliesCodeCustomFont",
        "attributes": {
          "category": "font-families",
          "type": "code",
          "item": "custom-font"
        },
        "path": [
          "font-families",
          "code",
          "custom-font"
        ]
      }
    },
    "lora": {
      "fallback-font": {
        "value": [
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesLoraFallbackFont",
        "attributes": {
          "category": "font-families",
          "type": "lora",
          "item": "fallback-font"
        },
        "path": [
          "font-families",
          "lora",
          "fallback-font"
        ]
      },
      "custom-font": {
        "value": [
          "Lora",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "Lora",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesLoraCustomFont",
        "attributes": {
          "category": "font-families",
          "type": "lora",
          "item": "custom-font"
        },
        "path": [
          "font-families",
          "lora",
          "custom-font"
        ]
      }
    },
    "chakrapetch": {
      "fallback-font": {
        "value": [
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesChakrapetchFallbackFont",
        "attributes": {
          "category": "font-families",
          "type": "chakrapetch",
          "item": "fallback-font"
        },
        "path": [
          "font-families",
          "chakrapetch",
          "fallback-font"
        ]
      },
      "custom-font": {
        "value": [
          "Chakra Petch",
          "Helvetica Neue",
          "sans-serif"
        ],
        "filePath": "src/font-families.json",
        "isSource": true,
        "original": {
          "value": [
            "Chakra Petch",
            "Helvetica Neue",
            "sans-serif"
          ]
        },
        "name": "FontFamiliesChakrapetchCustomFont",
        "attributes": {
          "category": "font-families",
          "type": "chakrapetch",
          "item": "custom-font"
        },
        "path": [
          "font-families",
          "chakrapetch",
          "custom-font"
        ]
      }
    }
  },
  "font": {
    "family-chakrapetch": {
      "chakrapetch": {
        "value": "Chakra Petch",
        "filePath": "src/font-family-chakrapetch-tokens.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontFamilyChakrapetchChakrapetch",
        "attributes": {
          "category": "font",
          "type": "family-chakrapetch",
          "item": "chakrapetch"
        },
        "path": [
          "font",
          "family-chakrapetch",
          "chakrapetch"
        ]
      }
    },
    "family-lora": {
      "lora": {
        "value": "Lora",
        "filePath": "src/font-family-lora-tokens.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontFamilyLoraLora",
        "attributes": {
          "category": "font",
          "type": "family-lora",
          "item": "lora"
        },
        "path": [
          "font",
          "family-lora",
          "lora"
        ]
      }
    },
    "family": {
      "body": {
        "value": "Open Sans",
        "filePath": "src/font-family-tokens.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontFamilyBody",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "body"
        },
        "path": [
          "font",
          "family",
          "body"
        ]
      },
      "headline": {
        "value": "Open Sans",
        "filePath": "src/font-family-tokens.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontFamilyHeadline",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "headline"
        },
        "path": [
          "font",
          "family",
          "headline"
        ]
      },
      "code": {
        "value": "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
        "filePath": "src/font-family-tokens.json",
        "isSource": true,
        "original": {
          "value": "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace"
        },
        "name": "FontFamilyCode",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "code"
        },
        "path": [
          "font",
          "family",
          "code"
        ]
      },
      "fallback": {
        "value": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans','Helvetica Neue', sans-serif",
        "filePath": "src/font-family-tokens.json",
        "isSource": true,
        "original": {
          "value": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans','Helvetica Neue', sans-serif"
        },
        "name": "FontFamilyFallback",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "fallback"
        },
        "path": [
          "font",
          "family",
          "fallback"
        ]
      }
    }
  },
  "font-size-lora-tokens": {
    "largedisplay": {
      "value": "4.5rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "4.5rem"
      },
      "name": "FontSizeLoraTokensLargedisplay",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "largedisplay"
      },
      "path": [
        "font-size-lora-tokens",
        "largedisplay"
      ]
    },
    "display": {
      "value": "3.75rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "3.75rem"
      },
      "name": "FontSizeLoraTokensDisplay",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "display"
      },
      "path": [
        "font-size-lora-tokens",
        "display"
      ]
    },
    "xxxlarge": {
      "value": "3rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "3rem"
      },
      "name": "FontSizeLoraTokensXxxlarge",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "xxxlarge"
      },
      "path": [
        "font-size-lora-tokens",
        "xxxlarge"
      ]
    },
    "xxlarge": {
      "value": "2.35rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "2.35rem"
      },
      "name": "FontSizeLoraTokensXxlarge",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "xxlarge"
      },
      "path": [
        "font-size-lora-tokens",
        "xxlarge"
      ]
    },
    "xlarge": {
      "value": "1.75rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "1.75rem"
      },
      "name": "FontSizeLoraTokensXlarge",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "xlarge"
      },
      "path": [
        "font-size-lora-tokens",
        "xlarge"
      ]
    },
    "large": {
      "value": "1.4rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "1.4rem"
      },
      "name": "FontSizeLoraTokensLarge",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "large"
      },
      "path": [
        "font-size-lora-tokens",
        "large"
      ]
    },
    "medium": {
      "value": "1.15rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "1.15rem"
      },
      "name": "FontSizeLoraTokensMedium",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "medium"
      },
      "path": [
        "font-size-lora-tokens",
        "medium"
      ]
    },
    "regular": {
      "value": "1rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "1rem"
      },
      "name": "FontSizeLoraTokensRegular",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "regular"
      },
      "path": [
        "font-size-lora-tokens",
        "regular"
      ]
    },
    "small": {
      "value": "0.9rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "0.9rem"
      },
      "name": "FontSizeLoraTokensSmall",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "small"
      },
      "path": [
        "font-size-lora-tokens",
        "small"
      ]
    },
    "xsmall": {
      "value": "0.8rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "0.8rem"
      },
      "name": "FontSizeLoraTokensXsmall",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "xsmall"
      },
      "path": [
        "font-size-lora-tokens",
        "xsmall"
      ]
    },
    "tiny": {
      "value": "0.7rem",
      "filePath": "src/font-size-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": "0.7rem"
      },
      "name": "FontSizeLoraTokensTiny",
      "attributes": {
        "category": "font-size-lora-tokens",
        "type": "tiny"
      },
      "path": [
        "font-size-lora-tokens",
        "tiny"
      ]
    }
  },
  "font-size-tokens--deprecated": {
    "xxxlarge": {
      "value": "2.35rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "2.35rem"
      },
      "name": "FontSizeTokensDeprecatedXxxlarge",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "xxxlarge"
      },
      "path": [
        "font-size-tokens--deprecated",
        "xxxlarge"
      ]
    },
    "xxlarge": {
      "value": "1.75rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "1.75rem"
      },
      "name": "FontSizeTokensDeprecatedXxlarge",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "xxlarge"
      },
      "path": [
        "font-size-tokens--deprecated",
        "xxlarge"
      ]
    },
    "xlarge": {
      "value": "1.4rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "1.4rem"
      },
      "name": "FontSizeTokensDeprecatedXlarge",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "xlarge"
      },
      "path": [
        "font-size-tokens--deprecated",
        "xlarge"
      ]
    },
    "large": {
      "value": "1.15rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "1.15rem"
      },
      "name": "FontSizeTokensDeprecatedLarge",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "large"
      },
      "path": [
        "font-size-tokens--deprecated",
        "large"
      ]
    },
    "medium": {
      "value": "1rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "1rem"
      },
      "name": "FontSizeTokensDeprecatedMedium",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "medium"
      },
      "path": [
        "font-size-tokens--deprecated",
        "medium"
      ]
    },
    "small": {
      "value": "0.9rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "0.9rem"
      },
      "name": "FontSizeTokensDeprecatedSmall",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "small"
      },
      "path": [
        "font-size-tokens--deprecated",
        "small"
      ]
    },
    "xsmall": {
      "value": "0.8rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "0.8rem"
      },
      "name": "FontSizeTokensDeprecatedXsmall",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "xsmall"
      },
      "path": [
        "font-size-tokens--deprecated",
        "xsmall"
      ]
    },
    "xxsmall": {
      "value": "0.7rem",
      "filePath": "src/font-size-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": "0.7rem"
      },
      "name": "FontSizeTokensDeprecatedXxsmall",
      "attributes": {
        "category": "font-size-tokens--deprecated",
        "type": "xxsmall"
      },
      "path": [
        "font-size-tokens--deprecated",
        "xxsmall"
      ]
    }
  },
  "font-size-tokens": {
    "largedisplay": {
      "value": "4.5rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "4.5rem"
      },
      "name": "FontSizeTokensLargedisplay",
      "attributes": {
        "category": "font-size-tokens",
        "type": "largedisplay"
      },
      "path": [
        "font-size-tokens",
        "largedisplay"
      ]
    },
    "display": {
      "value": "3.75rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "3.75rem"
      },
      "name": "FontSizeTokensDisplay",
      "attributes": {
        "category": "font-size-tokens",
        "type": "display"
      },
      "path": [
        "font-size-tokens",
        "display"
      ]
    },
    "xxxlarge": {
      "value": "3rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "3rem"
      },
      "name": "FontSizeTokensXxxlarge",
      "attributes": {
        "category": "font-size-tokens",
        "type": "xxxlarge"
      },
      "path": [
        "font-size-tokens",
        "xxxlarge"
      ]
    },
    "xxlarge": {
      "value": "2.35rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "2.35rem"
      },
      "name": "FontSizeTokensXxlarge",
      "attributes": {
        "category": "font-size-tokens",
        "type": "xxlarge"
      },
      "path": [
        "font-size-tokens",
        "xxlarge"
      ]
    },
    "xlarge": {
      "value": "1.75rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "1.75rem"
      },
      "name": "FontSizeTokensXlarge",
      "attributes": {
        "category": "font-size-tokens",
        "type": "xlarge"
      },
      "path": [
        "font-size-tokens",
        "xlarge"
      ]
    },
    "large": {
      "value": "1.4rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "1.4rem"
      },
      "name": "FontSizeTokensLarge",
      "attributes": {
        "category": "font-size-tokens",
        "type": "large"
      },
      "path": [
        "font-size-tokens",
        "large"
      ]
    },
    "medium": {
      "value": "1.15rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "1.15rem"
      },
      "name": "FontSizeTokensMedium",
      "attributes": {
        "category": "font-size-tokens",
        "type": "medium"
      },
      "path": [
        "font-size-tokens",
        "medium"
      ]
    },
    "regular": {
      "value": "1rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "1rem"
      },
      "name": "FontSizeTokensRegular",
      "attributes": {
        "category": "font-size-tokens",
        "type": "regular"
      },
      "path": [
        "font-size-tokens",
        "regular"
      ]
    },
    "small": {
      "value": "0.9rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "0.9rem"
      },
      "name": "FontSizeTokensSmall",
      "attributes": {
        "category": "font-size-tokens",
        "type": "small"
      },
      "path": [
        "font-size-tokens",
        "small"
      ]
    },
    "xsmall": {
      "value": "0.8rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "0.8rem"
      },
      "name": "FontSizeTokensXsmall",
      "attributes": {
        "category": "font-size-tokens",
        "type": "xsmall"
      },
      "path": [
        "font-size-tokens",
        "xsmall"
      ]
    },
    "tiny": {
      "value": "0.7rem",
      "filePath": "src/font-size-tokens.json",
      "isSource": true,
      "original": {
        "value": "0.7rem"
      },
      "name": "FontSizeTokensTiny",
      "attributes": {
        "category": "font-size-tokens",
        "type": "tiny"
      },
      "path": [
        "font-size-tokens",
        "tiny"
      ]
    }
  },
  "font-sizes": {
    "xxxlarge": {
      "font-size": {
        "21.333rem": {
          "value": "2.35rem",
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": "2.35rem"
          },
          "name": "FontSizesXxxlargeFontSize21333rem",
          "attributes": {
            "category": "font-sizes",
            "type": "xxxlarge",
            "item": "font-size",
            "subitem": "21.333rem"
          },
          "path": [
            "font-sizes",
            "xxxlarge",
            "font-size",
            "21.333rem"
          ]
        },
        "53.333rem": {
          "value": "2.5rem",
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": "2.5rem"
          },
          "name": "FontSizesXxxlargeFontSize53333rem",
          "attributes": {
            "category": "font-sizes",
            "type": "xxxlarge",
            "item": "font-size",
            "subitem": "53.333rem"
          },
          "path": [
            "font-sizes",
            "xxxlarge",
            "font-size",
            "53.333rem"
          ]
        }
      },
      "line-height": {
        "regular": {
          "value": 1.35,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.35
          },
          "name": "FontSizesXxxlargeLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "xxxlarge",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "xxxlarge",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesXxxlargeLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "xxxlarge",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "xxxlarge",
            "line-height",
            "tight"
          ]
        }
      }
    },
    "xxlarge": {
      "font-size": {
        "value": "1.75rem",
        "filePath": "src/font-sizes.json",
        "isSource": true,
        "original": {
          "value": "1.75rem"
        },
        "name": "FontSizesXxlargeFontSize",
        "attributes": {
          "category": "font-sizes",
          "type": "xxlarge",
          "item": "font-size"
        },
        "path": [
          "font-sizes",
          "xxlarge",
          "font-size"
        ]
      },
      "line-height": {
        "regular": {
          "value": 1.35,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.35
          },
          "name": "FontSizesXxlargeLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "xxlarge",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "xxlarge",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesXxlargeLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "xxlarge",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "xxlarge",
            "line-height",
            "tight"
          ]
        }
      }
    },
    "xlarge": {
      "font-size": {
        "value": "1.4rem",
        "filePath": "src/font-sizes.json",
        "isSource": true,
        "original": {
          "value": "1.4rem"
        },
        "name": "FontSizesXlargeFontSize",
        "attributes": {
          "category": "font-sizes",
          "type": "xlarge",
          "item": "font-size"
        },
        "path": [
          "font-sizes",
          "xlarge",
          "font-size"
        ]
      },
      "line-height": {
        "regular": {
          "value": 1.4,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.4
          },
          "name": "FontSizesXlargeLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "xlarge",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "xlarge",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesXlargeLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "xlarge",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "xlarge",
            "line-height",
            "tight"
          ]
        }
      }
    },
    "large": {
      "font-size": {
        "value": "1.15rem",
        "filePath": "src/font-sizes.json",
        "isSource": true,
        "original": {
          "value": "1.15rem"
        },
        "name": "FontSizesLargeFontSize",
        "attributes": {
          "category": "font-sizes",
          "type": "large",
          "item": "font-size"
        },
        "path": [
          "font-sizes",
          "large",
          "font-size"
        ]
      },
      "line-height": {
        "regular": {
          "value": 1.5,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.5
          },
          "name": "FontSizesLargeLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "large",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "large",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesLargeLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "large",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "large",
            "line-height",
            "tight"
          ]
        }
      }
    },
    "medium": {
      "font-size": {
        "value": "1rem",
        "filePath": "src/font-sizes.json",
        "isSource": true,
        "original": {
          "value": "1rem"
        },
        "name": "FontSizesMediumFontSize",
        "attributes": {
          "category": "font-sizes",
          "type": "medium",
          "item": "font-size"
        },
        "path": [
          "font-sizes",
          "medium",
          "font-size"
        ]
      },
      "line-height": {
        "regular": {
          "value": 1.55,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.55
          },
          "name": "FontSizesMediumLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "medium",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "medium",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesMediumLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "medium",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "medium",
            "line-height",
            "tight"
          ]
        }
      }
    },
    "small": {
      "font-size": {
        "value": "0.9rem",
        "filePath": "src/font-sizes.json",
        "isSource": true,
        "original": {
          "value": "0.9rem"
        },
        "name": "FontSizesSmallFontSize",
        "attributes": {
          "category": "font-sizes",
          "type": "small",
          "item": "font-size"
        },
        "path": [
          "font-sizes",
          "small",
          "font-size"
        ]
      },
      "line-height": {
        "regular": {
          "value": 1.45,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.45
          },
          "name": "FontSizesSmallLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "small",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "small",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesSmallLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "small",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "small",
            "line-height",
            "tight"
          ]
        }
      }
    },
    "xsmall": {
      "font-size": {
        "value": "0.8rem",
        "filePath": "src/font-sizes.json",
        "isSource": true,
        "original": {
          "value": "0.8rem"
        },
        "name": "FontSizesXsmallFontSize",
        "attributes": {
          "category": "font-sizes",
          "type": "xsmall",
          "item": "font-size"
        },
        "path": [
          "font-sizes",
          "xsmall",
          "font-size"
        ]
      },
      "line-height": {
        "regular": {
          "value": 1.4,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.4
          },
          "name": "FontSizesXsmallLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "xsmall",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "xsmall",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesXsmallLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "xsmall",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "xsmall",
            "line-height",
            "tight"
          ]
        }
      }
    },
    "xxsmall": {
      "font-size": {
        "value": "0.7rem",
        "filePath": "src/font-sizes.json",
        "isSource": true,
        "original": {
          "value": "0.7rem"
        },
        "name": "FontSizesXxsmallFontSize",
        "attributes": {
          "category": "font-sizes",
          "type": "xxsmall",
          "item": "font-size"
        },
        "path": [
          "font-sizes",
          "xxsmall",
          "font-size"
        ]
      },
      "line-height": {
        "regular": {
          "value": 1.4,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.4
          },
          "name": "FontSizesXxsmallLineHeightRegular",
          "attributes": {
            "category": "font-sizes",
            "type": "xxsmall",
            "item": "line-height",
            "subitem": "regular"
          },
          "path": [
            "font-sizes",
            "xxsmall",
            "line-height",
            "regular"
          ]
        },
        "tight": {
          "value": 1.2,
          "filePath": "src/font-sizes.json",
          "isSource": true,
          "original": {
            "value": 1.2
          },
          "name": "FontSizesXxsmallLineHeightTight",
          "attributes": {
            "category": "font-sizes",
            "type": "xxsmall",
            "item": "line-height",
            "subitem": "tight"
          },
          "path": [
            "font-sizes",
            "xxsmall",
            "line-height",
            "tight"
          ]
        }
      }
    }
  },
  "font-stacks": {
    "openSansLight": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansLightFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansLight",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansLight",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksOpenSansLightStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansLight",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansLight",
          "style"
        ]
      },
      "weight": {
        "value": 300,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 300
        },
        "name": "FontStacksOpenSansLightWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansLight",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansLight",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-light.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-light.woff2'"
          },
          "name": "FontStacksOpenSansLightSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansLight",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansLight",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-light.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-light.woff'"
          },
          "name": "FontStacksOpenSansLightSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansLight",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansLight",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansRegular": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansRegularFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansRegular",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansRegular",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksOpenSansRegularStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansRegular",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansRegular",
          "style"
        ]
      },
      "weight": {
        "value": 400,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontStacksOpenSansRegularWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansRegular",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansRegular",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-regular.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-regular.woff2'"
          },
          "name": "FontStacksOpenSansRegularSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansRegular",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansRegular",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-regular.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-regular.woff'"
          },
          "name": "FontStacksOpenSansRegularSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansRegular",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansRegular",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansItalic": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksOpenSansItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansItalic",
          "style"
        ]
      },
      "weight": {
        "value": 400,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontStacksOpenSansItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-italic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-italic.woff2'"
          },
          "name": "FontStacksOpenSansItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-italic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-italic.woff'"
          },
          "name": "FontStacksOpenSansItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansSemiBold": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansSemiBoldFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansSemiBold",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansSemiBold",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksOpenSansSemiBoldStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansSemiBold",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansSemiBold",
          "style"
        ]
      },
      "weight": {
        "value": 600,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontStacksOpenSansSemiBoldWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansSemiBold",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansSemiBold",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-semibold.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-semibold.woff2'"
          },
          "name": "FontStacksOpenSansSemiBoldSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansSemiBold",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansSemiBold",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-semibold.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-semibold.woff'"
          },
          "name": "FontStacksOpenSansSemiBoldSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansSemiBold",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansSemiBold",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansSemiBoldItalic": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansSemiBoldItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansSemiBoldItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansSemiBoldItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksOpenSansSemiBoldItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansSemiBoldItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansSemiBoldItalic",
          "style"
        ]
      },
      "weight": {
        "value": 600,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontStacksOpenSansSemiBoldItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansSemiBoldItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansSemiBoldItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-semibolditalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-semibolditalic.woff2'"
          },
          "name": "FontStacksOpenSansSemiBoldItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansSemiBoldItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansSemiBoldItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-semibolditalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-semibolditalic.woff'"
          },
          "name": "FontStacksOpenSansSemiBoldItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansSemiBoldItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansSemiBoldItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansBold": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansBoldFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansBold",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansBold",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksOpenSansBoldStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansBold",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansBold",
          "style"
        ]
      },
      "weight": {
        "value": 700,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 700
        },
        "name": "FontStacksOpenSansBoldWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansBold",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansBold",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-bold.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-bold.woff2'"
          },
          "name": "FontStacksOpenSansBoldSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansBold",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansBold",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-bold.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-bold.woff'"
          },
          "name": "FontStacksOpenSansBoldSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansBold",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansBold",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansBoldItalic": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansBoldItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansBoldItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansBoldItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksOpenSansBoldItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansBoldItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansBoldItalic",
          "style"
        ]
      },
      "weight": {
        "value": 700,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 700
        },
        "name": "FontStacksOpenSansBoldItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansBoldItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansBoldItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-bolditalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-bolditalic.woff2'"
          },
          "name": "FontStacksOpenSansBoldItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansBoldItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansBoldItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-bolditalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-bolditalic.woff'"
          },
          "name": "FontStacksOpenSansBoldItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansBoldItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansBoldItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansExtraBold": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansExtraBoldFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansExtraBold",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansExtraBold",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksOpenSansExtraBoldStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansExtraBold",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansExtraBold",
          "style"
        ]
      },
      "weight": {
        "value": 800,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 800
        },
        "name": "FontStacksOpenSansExtraBoldWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansExtraBold",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansExtraBold",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-extrabold.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-extrabold.woff2'"
          },
          "name": "FontStacksOpenSansExtraBoldSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansExtraBold",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansExtraBold",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-extrabold.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-extrabold.woff'"
          },
          "name": "FontStacksOpenSansExtraBoldSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansExtraBold",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansExtraBold",
            "src",
            "woff"
          ]
        }
      }
    },
    "openSansExtraBoldItalic": {
      "fontFamily": {
        "value": "Open Sans",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Open Sans"
        },
        "name": "FontStacksOpenSansExtraBoldItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansExtraBoldItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "openSansExtraBoldItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksOpenSansExtraBoldItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansExtraBoldItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "openSansExtraBoldItalic",
          "style"
        ]
      },
      "weight": {
        "value": 800,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 800
        },
        "name": "FontStacksOpenSansExtraBoldItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "openSansExtraBoldItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "openSansExtraBoldItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/opensans/opensans-extrabolditalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-extrabolditalic.woff2'"
          },
          "name": "FontStacksOpenSansExtraBoldItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansExtraBoldItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "openSansExtraBoldItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/opensans/opensans-extrabolditalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/opensans/opensans-extrabolditalic.woff'"
          },
          "name": "FontStacksOpenSansExtraBoldItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "openSansExtraBoldItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "openSansExtraBoldItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraBold": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraBoldFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraBold",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraBold",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksLoraBoldStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraBold",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraBold",
          "style"
        ]
      },
      "weight": {
        "value": 700,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 700
        },
        "name": "FontStacksLoraBoldWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraBold",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraBold",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-bold.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-bold.woff2'"
          },
          "name": "FontStacksLoraBoldSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraBold",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraBold",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-bold.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-bold.woff'"
          },
          "name": "FontStacksLoraBoldSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraBold",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraBold",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraBoldItalic": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraBoldItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraBoldItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraBoldItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksLoraBoldItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraBoldItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraBoldItalic",
          "style"
        ]
      },
      "weight": {
        "value": 700,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 700
        },
        "name": "FontStacksLoraBoldItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraBoldItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraBoldItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-bolditalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-bolditalic.woff2'"
          },
          "name": "FontStacksLoraBoldItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraBoldItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraBoldItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-bolditalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-bolditalic.woff'"
          },
          "name": "FontStacksLoraBoldItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraBoldItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraBoldItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraItalic": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksLoraItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraItalic",
          "style"
        ]
      },
      "weight": {
        "value": 400,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontStacksLoraItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-italic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-italic.woff2'"
          },
          "name": "FontStacksLoraItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-italic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-italic.woff'"
          },
          "name": "FontStacksLoraItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraRegular": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraRegularFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraRegular",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraRegular",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksLoraRegularStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraRegular",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraRegular",
          "style"
        ]
      },
      "weight": {
        "value": 400,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontStacksLoraRegularWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraRegular",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraRegular",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-regular.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-regular.woff2'"
          },
          "name": "FontStacksLoraRegularSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraRegular",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraRegular",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-regular.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-regular.woff'"
          },
          "name": "FontStacksLoraRegularSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraRegular",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraRegular",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraSemiBold": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraSemiBoldFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraSemiBold",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraSemiBold",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksLoraSemiBoldStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraSemiBold",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraSemiBold",
          "style"
        ]
      },
      "weight": {
        "value": 600,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontStacksLoraSemiBoldWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraSemiBold",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraSemiBold",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-semibold.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-semibold.woff2'"
          },
          "name": "FontStacksLoraSemiBoldSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraSemiBold",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraSemiBold",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-semibold.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-semibold.woff'"
          },
          "name": "FontStacksLoraSemiBoldSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraSemiBold",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraSemiBold",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraSemiBoldItalic": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraSemiBoldItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraSemiBoldItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraSemiBoldItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksLoraSemiBoldItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraSemiBoldItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraSemiBoldItalic",
          "style"
        ]
      },
      "weight": {
        "value": 600,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontStacksLoraSemiBoldItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraSemiBoldItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraSemiBoldItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-semibolditalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-semibolditalic.woff2'"
          },
          "name": "FontStacksLoraSemiBoldItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraSemiBoldItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraSemiBoldItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-semibolditalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-semibolditalic.woff'"
          },
          "name": "FontStacksLoraSemiBoldItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraSemiBoldItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraSemiBoldItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraMedium": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraMediumFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraMedium",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraMedium",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksLoraMediumStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraMedium",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraMedium",
          "style"
        ]
      },
      "weight": {
        "value": 500,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 500
        },
        "name": "FontStacksLoraMediumWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraMedium",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraMedium",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-medium.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-medium.woff2'"
          },
          "name": "FontStacksLoraMediumSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraMedium",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraMedium",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-medium.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-medium.woff'"
          },
          "name": "FontStacksLoraMediumSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraMedium",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraMedium",
            "src",
            "woff"
          ]
        }
      }
    },
    "loraMediumItalic": {
      "fontFamily": {
        "value": "Lora",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Lora"
        },
        "name": "FontStacksLoraMediumItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "loraMediumItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "loraMediumItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksLoraMediumItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "loraMediumItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "loraMediumItalic",
          "style"
        ]
      },
      "weight": {
        "value": 500,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 500
        },
        "name": "FontStacksLoraMediumItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "loraMediumItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "loraMediumItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/lora/lora-mediumitalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-mediumitalic.woff2'"
          },
          "name": "FontStacksLoraMediumItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "loraMediumItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "loraMediumItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/lora/lora-mediumitalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/lora/lora-mediumitalic.woff'"
          },
          "name": "FontStacksLoraMediumItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "loraMediumItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "loraMediumItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchBold": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchBoldFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchBold",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchBold",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksChakraPetchBoldStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchBold",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchBold",
          "style"
        ]
      },
      "weight": {
        "value": 700,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 700
        },
        "name": "FontStacksChakraPetchBoldWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchBold",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchBold",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Bold.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Bold.woff2'"
          },
          "name": "FontStacksChakraPetchBoldSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchBold",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchBold",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Bold.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Bold.woff'"
          },
          "name": "FontStacksChakraPetchBoldSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchBold",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchBold",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchBoldItalic": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchBoldItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchBoldItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchBoldItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksChakraPetchBoldItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchBoldItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchBoldItalic",
          "style"
        ]
      },
      "weight": {
        "value": 700,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 700
        },
        "name": "FontStacksChakraPetchBoldItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchBoldItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchBoldItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-BoldItalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-BoldItalic.woff2'"
          },
          "name": "FontStacksChakraPetchBoldItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchBoldItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchBoldItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-BoldItalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-BoldItalic.woff'"
          },
          "name": "FontStacksChakraPetchBoldItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchBoldItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchBoldItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchItalic": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksChakraPetchItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchItalic",
          "style"
        ]
      },
      "weight": {
        "value": 400,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontStacksChakraPetchItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Italic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Italic.woff2'"
          },
          "name": "FontStacksChakraPetchItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Italic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Italic.woff'"
          },
          "name": "FontStacksChakraPetchItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchLight": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchLightFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchLight",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchLight",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksChakraPetchLightStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchLight",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchLight",
          "style"
        ]
      },
      "weight": {
        "value": 300,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 300
        },
        "name": "FontStacksChakraPetchLightWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchLight",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchLight",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Light.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Light.woff2'"
          },
          "name": "FontStacksChakraPetchLightSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchLight",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchLight",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Light.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Light.woff'"
          },
          "name": "FontStacksChakraPetchLightSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchLight",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchLight",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchLightItalic": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchLightItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchLightItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchLightItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksChakraPetchLightItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchLightItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchLightItalic",
          "style"
        ]
      },
      "weight": {
        "value": 300,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 300
        },
        "name": "FontStacksChakraPetchLightItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchLightItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchLightItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-LightItalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-LightItalic.woff2'"
          },
          "name": "FontStacksChakraPetchLightItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchLightItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchLightItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-LightItalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-LightItalic.woff'"
          },
          "name": "FontStacksChakraPetchLightItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchLightItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchLightItalic",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchRegular": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchRegularFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchRegular",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchRegular",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksChakraPetchRegularStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchRegular",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchRegular",
          "style"
        ]
      },
      "weight": {
        "value": 400,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontStacksChakraPetchRegularWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchRegular",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchRegular",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Regular.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Regular.woff2'"
          },
          "name": "FontStacksChakraPetchRegularSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchRegular",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchRegular",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Regular.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-Regular.woff'"
          },
          "name": "FontStacksChakraPetchRegularSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchRegular",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchRegular",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchSemiBold": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchSemiBoldFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchSemiBold",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchSemiBold",
          "fontFamily"
        ]
      },
      "style": {
        "value": "normal",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "normal"
        },
        "name": "FontStacksChakraPetchSemiBoldStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchSemiBold",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchSemiBold",
          "style"
        ]
      },
      "weight": {
        "value": 600,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontStacksChakraPetchSemiBoldWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchSemiBold",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchSemiBold",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBold.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBold.woff2'"
          },
          "name": "FontStacksChakraPetchSemiBoldSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchSemiBold",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchSemiBold",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBold.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBold.woff'"
          },
          "name": "FontStacksChakraPetchSemiBoldSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchSemiBold",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchSemiBold",
            "src",
            "woff"
          ]
        }
      }
    },
    "chakraPetchSemiBoldItalic": {
      "fontFamily": {
        "value": "Chakra Petch",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "Chakra Petch"
        },
        "name": "FontStacksChakraPetchSemiBoldItalicFontFamily",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchSemiBoldItalic",
          "item": "fontFamily"
        },
        "path": [
          "font-stacks",
          "chakraPetchSemiBoldItalic",
          "fontFamily"
        ]
      },
      "style": {
        "value": "italic",
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": "italic"
        },
        "name": "FontStacksChakraPetchSemiBoldItalicStyle",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchSemiBoldItalic",
          "item": "style"
        },
        "path": [
          "font-stacks",
          "chakraPetchSemiBoldItalic",
          "style"
        ]
      },
      "weight": {
        "value": 600,
        "filePath": "src/font-stacks.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontStacksChakraPetchSemiBoldItalicWeight",
        "attributes": {
          "category": "font-stacks",
          "type": "chakraPetchSemiBoldItalic",
          "item": "weight"
        },
        "path": [
          "font-stacks",
          "chakraPetchSemiBoldItalic",
          "weight"
        ]
      },
      "src": {
        "woff2": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBoldItalic.woff2'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBoldItalic.woff2'"
          },
          "name": "FontStacksChakraPetchSemiBoldItalicSrcWoff2",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchSemiBoldItalic",
            "item": "src",
            "subitem": "woff2"
          },
          "path": [
            "font-stacks",
            "chakraPetchSemiBoldItalic",
            "src",
            "woff2"
          ]
        },
        "woff": {
          "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBoldItalic.woff'",
          "filePath": "src/font-stacks.json",
          "isSource": true,
          "original": {
            "value": "'~@bolt/global/fonts/chakrapetch/ChakraPetch-SemiBoldItalic.woff'"
          },
          "name": "FontStacksChakraPetchSemiBoldItalicSrcWoff",
          "attributes": {
            "category": "font-stacks",
            "type": "chakraPetchSemiBoldItalic",
            "item": "src",
            "subitem": "woff"
          },
          "path": [
            "font-stacks",
            "chakraPetchSemiBoldItalic",
            "src",
            "woff"
          ]
        }
      }
    }
  },
  "font-weight-lora-tokens": {
    "regular": {
      "value": 400,
      "filePath": "src/font-weight-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": 400
      },
      "name": "FontWeightLoraTokensRegular",
      "attributes": {
        "category": "font-weight-lora-tokens",
        "type": "regular"
      },
      "path": [
        "font-weight-lora-tokens",
        "regular"
      ]
    },
    "medium": {
      "value": 500,
      "filePath": "src/font-weight-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": 500
      },
      "name": "FontWeightLoraTokensMedium",
      "attributes": {
        "category": "font-weight-lora-tokens",
        "type": "medium"
      },
      "path": [
        "font-weight-lora-tokens",
        "medium"
      ]
    },
    "semibold": {
      "value": 600,
      "filePath": "src/font-weight-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": 600
      },
      "name": "FontWeightLoraTokensSemibold",
      "attributes": {
        "category": "font-weight-lora-tokens",
        "type": "semibold"
      },
      "path": [
        "font-weight-lora-tokens",
        "semibold"
      ]
    },
    "bold": {
      "value": 700,
      "filePath": "src/font-weight-lora-tokens.json",
      "isSource": true,
      "original": {
        "value": 700
      },
      "name": "FontWeightLoraTokensBold",
      "attributes": {
        "category": "font-weight-lora-tokens",
        "type": "bold"
      },
      "path": [
        "font-weight-lora-tokens",
        "bold"
      ]
    }
  },
  "font-weight-tokens": {
    "light": {
      "value": 300,
      "filePath": "src/font-weight-tokens.json",
      "isSource": true,
      "original": {
        "value": 300
      },
      "name": "FontWeightTokensLight",
      "attributes": {
        "category": "font-weight-tokens",
        "type": "light"
      },
      "path": [
        "font-weight-tokens",
        "light"
      ]
    },
    "regular": {
      "value": 400,
      "filePath": "src/font-weight-tokens.json",
      "isSource": true,
      "original": {
        "value": 400
      },
      "name": "FontWeightTokensRegular",
      "attributes": {
        "category": "font-weight-tokens",
        "type": "regular"
      },
      "path": [
        "font-weight-tokens",
        "regular"
      ]
    },
    "semibold": {
      "value": 600,
      "filePath": "src/font-weight-tokens.json",
      "isSource": true,
      "original": {
        "value": 600
      },
      "name": "FontWeightTokensSemibold",
      "attributes": {
        "category": "font-weight-tokens",
        "type": "semibold"
      },
      "path": [
        "font-weight-tokens",
        "semibold"
      ]
    },
    "bold": {
      "value": 700,
      "filePath": "src/font-weight-tokens.json",
      "isSource": true,
      "original": {
        "value": 700
      },
      "name": "FontWeightTokensBold",
      "attributes": {
        "category": "font-weight-tokens",
        "type": "bold"
      },
      "path": [
        "font-weight-tokens",
        "bold"
      ]
    },
    "extrabold": {
      "value": 800,
      "filePath": "src/font-weight-tokens.json",
      "isSource": true,
      "original": {
        "value": 800
      },
      "name": "FontWeightTokensExtrabold",
      "attributes": {
        "category": "font-weight-tokens",
        "type": "extrabold"
      },
      "path": [
        "font-weight-tokens",
        "extrabold"
      ]
    }
  },
  "height": {
    "none": {
      "value": 0,
      "filePath": "src/height.json",
      "isSource": true,
      "original": {
        "value": 0
      },
      "name": "HeightNone",
      "attributes": {
        "category": "height",
        "type": "none"
      },
      "path": [
        "height",
        "none"
      ]
    },
    "auto": {
      "value": "auto",
      "filePath": "src/height.json",
      "isSource": true,
      "original": {
        "value": "auto"
      },
      "name": "HeightAuto",
      "attributes": {
        "category": "height",
        "type": "auto"
      },
      "path": [
        "height",
        "auto"
      ]
    },
    "full": {
      "value": "100%",
      "filePath": "src/height.json",
      "isSource": true,
      "original": {
        "value": "100%"
      },
      "name": "HeightFull",
      "attributes": {
        "category": "height",
        "type": "full"
      },
      "path": [
        "height",
        "full"
      ]
    },
    "fullscreen": {
      "value": "100vh",
      "filePath": "src/height.json",
      "isSource": true,
      "original": {
        "value": "100vh"
      },
      "name": "HeightFullscreen",
      "attributes": {
        "category": "height",
        "type": "fullscreen"
      },
      "path": [
        "height",
        "fullscreen"
      ]
    }
  },
  "letter-spacing-tokens": {
    "narrow": {
      "value": "-0.025rem",
      "filePath": "src/letter-spacing-tokens.json",
      "isSource": true,
      "original": {
        "value": "-0.025rem"
      },
      "name": "LetterSpacingTokensNarrow",
      "attributes": {
        "category": "letter-spacing-tokens",
        "type": "narrow"
      },
      "path": [
        "letter-spacing-tokens",
        "narrow"
      ]
    },
    "regular": {
      "value": 0,
      "filePath": "src/letter-spacing-tokens.json",
      "isSource": true,
      "original": {
        "value": 0
      },
      "name": "LetterSpacingTokensRegular",
      "attributes": {
        "category": "letter-spacing-tokens",
        "type": "regular"
      },
      "path": [
        "letter-spacing-tokens",
        "regular"
      ]
    },
    "wide": {
      "value": "0.05rem",
      "filePath": "src/letter-spacing-tokens.json",
      "isSource": true,
      "original": {
        "value": "0.05rem"
      },
      "name": "LetterSpacingTokensWide",
      "attributes": {
        "category": "letter-spacing-tokens",
        "type": "wide"
      },
      "path": [
        "letter-spacing-tokens",
        "wide"
      ]
    }
  },
  "line-height-multiplier-tokens": {
    "tight": {
      "value": 0.77,
      "filePath": "src/line-height-multiplier-tokens.json",
      "isSource": true,
      "original": {
        "value": 0.77
      },
      "name": "LineHeightMultiplierTokensTight",
      "attributes": {
        "category": "line-height-multiplier-tokens",
        "type": "tight"
      },
      "path": [
        "line-height-multiplier-tokens",
        "tight"
      ]
    },
    "regular": {
      "value": 1,
      "filePath": "src/line-height-multiplier-tokens.json",
      "isSource": true,
      "original": {
        "value": 1
      },
      "name": "LineHeightMultiplierTokensRegular",
      "attributes": {
        "category": "line-height-multiplier-tokens",
        "type": "regular"
      },
      "path": [
        "line-height-multiplier-tokens",
        "regular"
      ]
    },
    "loose": {
      "value": 1.2,
      "filePath": "src/line-height-multiplier-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.2
      },
      "name": "LineHeightMultiplierTokensLoose",
      "attributes": {
        "category": "line-height-multiplier-tokens",
        "type": "loose"
      },
      "path": [
        "line-height-multiplier-tokens",
        "loose"
      ]
    }
  },
  "line-height-tokens-deprecated": {
    "xxxlarge": {
      "value": 1.25,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.25
      },
      "name": "LineHeightTokensDeprecatedXxxlarge",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "xxxlarge"
      },
      "path": [
        "line-height-tokens-deprecated",
        "xxxlarge"
      ]
    },
    "xxlarge": {
      "value": 1.35,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.35
      },
      "name": "LineHeightTokensDeprecatedXxlarge",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "xxlarge"
      },
      "path": [
        "line-height-tokens-deprecated",
        "xxlarge"
      ]
    },
    "xlarge": {
      "value": 1.4,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.4
      },
      "name": "LineHeightTokensDeprecatedXlarge",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "xlarge"
      },
      "path": [
        "line-height-tokens-deprecated",
        "xlarge"
      ]
    },
    "large": {
      "value": 1.5,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.5
      },
      "name": "LineHeightTokensDeprecatedLarge",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "large"
      },
      "path": [
        "line-height-tokens-deprecated",
        "large"
      ]
    },
    "medium": {
      "value": 1.55,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.55
      },
      "name": "LineHeightTokensDeprecatedMedium",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "medium"
      },
      "path": [
        "line-height-tokens-deprecated",
        "medium"
      ]
    },
    "small": {
      "value": 1.45,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.45
      },
      "name": "LineHeightTokensDeprecatedSmall",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "small"
      },
      "path": [
        "line-height-tokens-deprecated",
        "small"
      ]
    },
    "xsmall": {
      "value": 1.4,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.4
      },
      "name": "LineHeightTokensDeprecatedXsmall",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "xsmall"
      },
      "path": [
        "line-height-tokens-deprecated",
        "xsmall"
      ]
    },
    "xxsmall": {
      "value": 1.4,
      "filePath": "src/line-height-tokens--deprecated.json",
      "isSource": true,
      "original": {
        "value": 1.4
      },
      "name": "LineHeightTokensDeprecatedXxsmall",
      "attributes": {
        "category": "line-height-tokens-deprecated",
        "type": "xxsmall"
      },
      "path": [
        "line-height-tokens-deprecated",
        "xxsmall"
      ]
    }
  },
  "line-height-tokens": {
    "xlargedisplay": {
      "value": 1.05,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.05
      },
      "name": "LineHeightTokensXlargedisplay",
      "attributes": {
        "category": "line-height-tokens",
        "type": "xlargedisplay"
      },
      "path": [
        "line-height-tokens",
        "xlargedisplay"
      ]
    },
    "largedisplay": {
      "value": 1.05,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.05
      },
      "name": "LineHeightTokensLargedisplay",
      "attributes": {
        "category": "line-height-tokens",
        "type": "largedisplay"
      },
      "path": [
        "line-height-tokens",
        "largedisplay"
      ]
    },
    "display": {
      "value": 1.05,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.05
      },
      "name": "LineHeightTokensDisplay",
      "attributes": {
        "category": "line-height-tokens",
        "type": "display"
      },
      "path": [
        "line-height-tokens",
        "display"
      ]
    },
    "xxxlarge": {
      "value": 1.2,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.2
      },
      "name": "LineHeightTokensXxxlarge",
      "attributes": {
        "category": "line-height-tokens",
        "type": "xxxlarge"
      },
      "path": [
        "line-height-tokens",
        "xxxlarge"
      ]
    },
    "xxlarge": {
      "value": 1.2,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.2
      },
      "name": "LineHeightTokensXxlarge",
      "attributes": {
        "category": "line-height-tokens",
        "type": "xxlarge"
      },
      "path": [
        "line-height-tokens",
        "xxlarge"
      ]
    },
    "xlarge": {
      "value": 1.3,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.3
      },
      "name": "LineHeightTokensXlarge",
      "attributes": {
        "category": "line-height-tokens",
        "type": "xlarge"
      },
      "path": [
        "line-height-tokens",
        "xlarge"
      ]
    },
    "large": {
      "value": 1.4,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.4
      },
      "name": "LineHeightTokensLarge",
      "attributes": {
        "category": "line-height-tokens",
        "type": "large"
      },
      "path": [
        "line-height-tokens",
        "large"
      ]
    },
    "medium": {
      "value": 1.5,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.5
      },
      "name": "LineHeightTokensMedium",
      "attributes": {
        "category": "line-height-tokens",
        "type": "medium"
      },
      "path": [
        "line-height-tokens",
        "medium"
      ]
    },
    "regular": {
      "value": 1.55,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.55
      },
      "name": "LineHeightTokensRegular",
      "attributes": {
        "category": "line-height-tokens",
        "type": "regular"
      },
      "path": [
        "line-height-tokens",
        "regular"
      ]
    },
    "small": {
      "value": 1.45,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.45
      },
      "name": "LineHeightTokensSmall",
      "attributes": {
        "category": "line-height-tokens",
        "type": "small"
      },
      "path": [
        "line-height-tokens",
        "small"
      ]
    },
    "xsmall": {
      "value": 1.4,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.4
      },
      "name": "LineHeightTokensXsmall",
      "attributes": {
        "category": "line-height-tokens",
        "type": "xsmall"
      },
      "path": [
        "line-height-tokens",
        "xsmall"
      ]
    },
    "tiny": {
      "value": 1.4,
      "filePath": "src/line-height-tokens.json",
      "isSource": true,
      "original": {
        "value": 1.4
      },
      "name": "LineHeightTokensTiny",
      "attributes": {
        "category": "line-height-tokens",
        "type": "tiny"
      },
      "path": [
        "line-height-tokens",
        "tiny"
      ]
    }
  },
  "opacity": {
    "0": {
      "value": 0,
      "filePath": "src/opacity.json",
      "isSource": true,
      "original": {
        "value": 0
      },
      "name": "Opacity0",
      "attributes": {
        "category": "opacity",
        "type": "0"
      },
      "path": [
        "opacity",
        "0"
      ]
    },
    "20": {
      "value": 0.2,
      "filePath": "src/opacity.json",
      "isSource": true,
      "original": {
        "value": 0.2
      },
      "name": "Opacity20",
      "attributes": {
        "category": "opacity",
        "type": "20"
      },
      "path": [
        "opacity",
        "20"
      ]
    },
    "40": {
      "value": 0.4,
      "filePath": "src/opacity.json",
      "isSource": true,
      "original": {
        "value": 0.4
      },
      "name": "Opacity40",
      "attributes": {
        "category": "opacity",
        "type": "40"
      },
      "path": [
        "opacity",
        "40"
      ]
    },
    "60": {
      "value": 0.6,
      "filePath": "src/opacity.json",
      "isSource": true,
      "original": {
        "value": 0.6
      },
      "name": "Opacity60",
      "attributes": {
        "category": "opacity",
        "type": "60"
      },
      "path": [
        "opacity",
        "60"
      ]
    },
    "80": {
      "value": 0.8,
      "filePath": "src/opacity.json",
      "isSource": true,
      "original": {
        "value": 0.8
      },
      "name": "Opacity80",
      "attributes": {
        "category": "opacity",
        "type": "80"
      },
      "path": [
        "opacity",
        "80"
      ]
    },
    "100": {
      "value": 1,
      "filePath": "src/opacity.json",
      "isSource": true,
      "original": {
        "value": 1
      },
      "name": "Opacity100",
      "attributes": {
        "category": "opacity",
        "type": "100"
      },
      "path": [
        "opacity",
        "100"
      ]
    }
  },
  "shadows": {
    "sets": {
      "level-10": {
        "base": {
          "value": "0 1px 2px 1px rgba(6, 10, 36, 0.08)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 1px 2px 1px rgba(6, 10, 36, 0.08)"
          },
          "name": "ShadowsSetsLevel10Base",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-10",
            "subitem": "base"
          },
          "path": [
            "shadows",
            "sets",
            "level-10",
            "base"
          ]
        }
      },
      "level-20": {
        "base": {
          "value": "0 1px 4px 1px rgba(6, 10, 36, 0.1), 0 5px 10px 0 rgba(6, 10, 36, 0.08)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 1px 4px 1px rgba(6, 10, 36, 0.1), 0 5px 10px 0 rgba(6, 10, 36, 0.08)"
          },
          "name": "ShadowsSetsLevel20Base",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-20",
            "subitem": "base"
          },
          "path": [
            "shadows",
            "sets",
            "level-20",
            "base"
          ]
        },
        "raised": {
          "value": "0 1px 8px 1px rgba(6, 10, 36, 0.18), 0 5px 10px 1px rgba(6, 10, 36, 0.15), 0 15px 30px 0 rgba(6, 10, 36, 0.16)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 1px 8px 1px rgba(6, 10, 36, 0.18), 0 5px 10px 1px rgba(6, 10, 36, 0.15), 0 15px 30px 0 rgba(6, 10, 36, 0.16)"
          },
          "name": "ShadowsSetsLevel20Raised",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-20",
            "subitem": "raised"
          },
          "path": [
            "shadows",
            "sets",
            "level-20",
            "raised"
          ]
        }
      },
      "level-30": {
        "base": {
          "value": "0 8px 15px 1px rgba(6, 10, 36, 0.1), 0 18px 24px 1px rgba(6, 10, 36, 0.12)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 8px 15px 1px rgba(6, 10, 36, 0.1), 0 18px 24px 1px rgba(6, 10, 36, 0.12)"
          },
          "name": "ShadowsSetsLevel30Base",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-30",
            "subitem": "base"
          },
          "path": [
            "shadows",
            "sets",
            "level-30",
            "base"
          ]
        },
        "raised": {
          "value": "0 8px 15px 1px rgba(6, 10, 36, 0.1), 0 24px 36px 1px rgba(6, 10, 36, 0.18), 0 35px 50px 0 rgba(6, 10, 36, 0.25)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 8px 15px 1px rgba(6, 10, 36, 0.1), 0 24px 36px 1px rgba(6, 10, 36, 0.18), 0 35px 50px 0 rgba(6, 10, 36, 0.25)"
          },
          "name": "ShadowsSetsLevel30Raised",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-30",
            "subitem": "raised"
          },
          "path": [
            "shadows",
            "sets",
            "level-30",
            "raised"
          ]
        }
      },
      "level-40": {
        "base": {
          "value": "0 10px 20px 1px rgba(6, 10, 36, 0.1), 0 24px 36px 1px rgba(6, 10, 36, 0.18)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 20px 1px rgba(6, 10, 36, 0.1), 0 24px 36px 1px rgba(6, 10, 36, 0.18)"
          },
          "name": "ShadowsSetsLevel40Base",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-40",
            "subitem": "base"
          },
          "path": [
            "shadows",
            "sets",
            "level-40",
            "base"
          ]
        },
        "raised": {
          "value": "0 10px 20px 1px rgba(6, 10, 36, 0.1), 0 36px 49px 1px rgba(6, 10, 36, 0.2), 0 45px 65px 0 rgba(6, 10, 36, 0.3)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 20px 1px rgba(6, 10, 36, 0.1), 0 36px 49px 1px rgba(6, 10, 36, 0.2), 0 45px 65px 0 rgba(6, 10, 36, 0.3)"
          },
          "name": "ShadowsSetsLevel40Raised",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-40",
            "subitem": "raised"
          },
          "path": [
            "shadows",
            "sets",
            "level-40",
            "raised"
          ]
        }
      },
      "level-50": {
        "base": {
          "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 40px 48px 1px rgba(6, 10, 36, 0.25)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 40px 48px 1px rgba(6, 10, 36, 0.25)"
          },
          "name": "ShadowsSetsLevel50Base",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-50",
            "subitem": "base"
          },
          "path": [
            "shadows",
            "sets",
            "level-50",
            "base"
          ]
        },
        "raised": {
          "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 50px 70px 1px rgba(6, 10, 36, 0.2), 0 55px 80px 0 rgba(6, 10, 36, 0.3)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 50px 70px 1px rgba(6, 10, 36, 0.2), 0 55px 80px 0 rgba(6, 10, 36, 0.3)"
          },
          "name": "ShadowsSetsLevel50Raised",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-50",
            "subitem": "raised"
          },
          "path": [
            "shadows",
            "sets",
            "level-50",
            "raised"
          ]
        }
      },
      "level-60": {
        "base": {
          "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 50px 60px 1px rgba(6, 10, 36, 0.4)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 50px 60px 1px rgba(6, 10, 36, 0.4)"
          },
          "name": "ShadowsSetsLevel60Base",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-60",
            "subitem": "base"
          },
          "path": [
            "shadows",
            "sets",
            "level-60",
            "base"
          ]
        },
        "raised": {
          "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 70px 80px 1px rgba(6, 10, 36, 0.2), 0 80px 120px 0 rgba(6, 10, 36, 0.35)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 70px 80px 1px rgba(6, 10, 36, 0.2), 0 80px 120px 0 rgba(6, 10, 36, 0.35)"
          },
          "name": "ShadowsSetsLevel60Raised",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-60",
            "subitem": "raised"
          },
          "path": [
            "shadows",
            "sets",
            "level-60",
            "raised"
          ]
        }
      },
      "level-70": {
        "base": {
          "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 80px 90px 1px rgba(6, 10, 36, 0.4)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 80px 90px 1px rgba(6, 10, 36, 0.4)"
          },
          "name": "ShadowsSetsLevel70Base",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-70",
            "subitem": "base"
          },
          "path": [
            "shadows",
            "sets",
            "level-70",
            "base"
          ]
        },
        "raised": {
          "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 110px 130px 1px rgba(6, 10, 36, 0.25), 0 130px 150px 0 rgba(6, 10, 36, 0.35)",
          "filePath": "src/shadows.json",
          "isSource": true,
          "original": {
            "value": "0 10px 30px 1px rgba(6, 10, 36, 0.1), 0 110px 130px 1px rgba(6, 10, 36, 0.25), 0 130px 150px 0 rgba(6, 10, 36, 0.35)"
          },
          "name": "ShadowsSetsLevel70Raised",
          "attributes": {
            "category": "shadows",
            "type": "sets",
            "item": "level-70",
            "subitem": "raised"
          },
          "path": [
            "shadows",
            "sets",
            "level-70",
            "raised"
          ]
        }
      }
    }
  },
  "spacing": {
    "base": {
      "x": {
        "value": 1.55,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 1.55
        },
        "name": "SpacingBaseX",
        "attributes": {
          "category": "spacing",
          "type": "base",
          "item": "x"
        },
        "path": [
          "spacing",
          "base",
          "x"
        ]
      },
      "y": {
        "value": 1.35,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 1.35
        },
        "name": "SpacingBaseY",
        "attributes": {
          "category": "spacing",
          "type": "base",
          "item": "y"
        },
        "path": [
          "spacing",
          "base",
          "y"
        ]
      }
    },
    "directions": {
      "value": [
        "top",
        "right",
        "bottom",
        "left"
      ],
      "filePath": "src/spacing.json",
      "isSource": true,
      "original": {
        "value": [
          "top",
          "right",
          "bottom",
          "left"
        ]
      },
      "name": "SpacingDirections",
      "attributes": {
        "category": "spacing",
        "type": "directions"
      },
      "path": [
        "spacing",
        "directions"
      ]
    },
    "multipliers": {
      "xxsmall": {
        "value": 0.125,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 0.125
        },
        "name": "SpacingMultipliersXxsmall",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "xxsmall"
        },
        "path": [
          "spacing",
          "multipliers",
          "xxsmall"
        ]
      },
      "xsmall": {
        "value": 0.25,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 0.25
        },
        "name": "SpacingMultipliersXsmall",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "xsmall"
        },
        "path": [
          "spacing",
          "multipliers",
          "xsmall"
        ]
      },
      "small": {
        "value": 0.5,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 0.5
        },
        "name": "SpacingMultipliersSmall",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "small"
        },
        "path": [
          "spacing",
          "multipliers",
          "small"
        ]
      },
      "medium": {
        "value": 1,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 1
        },
        "name": "SpacingMultipliersMedium",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "medium"
        },
        "path": [
          "spacing",
          "multipliers",
          "medium"
        ]
      },
      "large": {
        "value": 2,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 2
        },
        "name": "SpacingMultipliersLarge",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "large"
        },
        "path": [
          "spacing",
          "multipliers",
          "large"
        ]
      },
      "xlarge": {
        "value": 4,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 4
        },
        "name": "SpacingMultipliersXlarge",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "xlarge"
        },
        "path": [
          "spacing",
          "multipliers",
          "xlarge"
        ]
      },
      "xxlarge": {
        "value": 8,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 8
        },
        "name": "SpacingMultipliersXxlarge",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "xxlarge"
        },
        "path": [
          "spacing",
          "multipliers",
          "xxlarge"
        ]
      },
      "xxxlarge": {
        "value": 16,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 16
        },
        "name": "SpacingMultipliersXxxlarge",
        "attributes": {
          "category": "spacing",
          "type": "multipliers",
          "item": "xxxlarge"
        },
        "path": [
          "spacing",
          "multipliers",
          "xxxlarge"
        ]
      }
    },
    "props": {
      "value": [
        "margin",
        "padding"
      ],
      "filePath": "src/spacing.json",
      "isSource": true,
      "original": {
        "value": [
          "margin",
          "padding"
        ]
      },
      "name": "SpacingProps",
      "attributes": {
        "category": "spacing",
        "type": "props"
      },
      "path": [
        "spacing",
        "props"
      ]
    },
    "scale": {
      "base": {
        "value": 1,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 1
        },
        "name": "SpacingScaleBase",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "base"
        },
        "path": [
          "spacing",
          "scale",
          "base"
        ]
      },
      "xxsmall": {
        "value": 0.125,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 0.125
        },
        "name": "SpacingScaleXxsmall",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "xxsmall"
        },
        "path": [
          "spacing",
          "scale",
          "xxsmall"
        ]
      },
      "xsmall": {
        "value": 0.25,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 0.25
        },
        "name": "SpacingScaleXsmall",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "xsmall"
        },
        "path": [
          "spacing",
          "scale",
          "xsmall"
        ]
      },
      "small": {
        "value": 0.5,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 0.5
        },
        "name": "SpacingScaleSmall",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "small"
        },
        "path": [
          "spacing",
          "scale",
          "small"
        ]
      },
      "medium": {
        "value": 1,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 1
        },
        "name": "SpacingScaleMedium",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "medium"
        },
        "path": [
          "spacing",
          "scale",
          "medium"
        ]
      },
      "large": {
        "value": 2,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 2
        },
        "name": "SpacingScaleLarge",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "large"
        },
        "path": [
          "spacing",
          "scale",
          "large"
        ]
      },
      "xlarge": {
        "value": 4,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 4
        },
        "name": "SpacingScaleXlarge",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "xlarge"
        },
        "path": [
          "spacing",
          "scale",
          "xlarge"
        ]
      },
      "xxlarge": {
        "value": 8,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 8
        },
        "name": "SpacingScaleXxlarge",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "xxlarge"
        },
        "path": [
          "spacing",
          "scale",
          "xxlarge"
        ]
      },
      "xxxlarge": {
        "value": 16,
        "filePath": "src/spacing.json",
        "isSource": true,
        "original": {
          "value": 16
        },
        "name": "SpacingScaleXxxlarge",
        "attributes": {
          "category": "spacing",
          "type": "scale",
          "item": "xxxlarge"
        },
        "path": [
          "spacing",
          "scale",
          "xxxlarge"
        ]
      }
    }
  },
  "text-decoration": {
    "none": {
      "value": "none",
      "filePath": "src/text-decoration.json",
      "isSource": true,
      "original": {
        "value": "none"
      },
      "name": "TextDecorationNone",
      "attributes": {
        "category": "text-decoration",
        "type": "none"
      },
      "path": [
        "text-decoration",
        "none"
      ]
    },
    "underline": {
      "value": "underline",
      "filePath": "src/text-decoration.json",
      "isSource": true,
      "original": {
        "value": "underline"
      },
      "name": "TextDecorationUnderline",
      "attributes": {
        "category": "text-decoration",
        "type": "underline"
      },
      "path": [
        "text-decoration",
        "underline"
      ]
    },
    "line-through": {
      "value": "line-through",
      "filePath": "src/text-decoration.json",
      "isSource": true,
      "original": {
        "value": "line-through"
      },
      "name": "TextDecorationLineThrough",
      "attributes": {
        "category": "text-decoration",
        "type": "line-through"
      },
      "path": [
        "text-decoration",
        "line-through"
      ]
    }
  },
  "widths": {
    "1": {
      "value": 1,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 1
      },
      "name": "Widths1",
      "attributes": {
        "category": "widths",
        "type": "1"
      },
      "path": [
        "widths",
        "1"
      ]
    },
    "2": {
      "value": 2,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 2
      },
      "name": "Widths2",
      "attributes": {
        "category": "widths",
        "type": "2"
      },
      "path": [
        "widths",
        "2"
      ]
    },
    "3": {
      "value": 3,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 3
      },
      "name": "Widths3",
      "attributes": {
        "category": "widths",
        "type": "3"
      },
      "path": [
        "widths",
        "3"
      ]
    },
    "4": {
      "value": 4,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 4
      },
      "name": "Widths4",
      "attributes": {
        "category": "widths",
        "type": "4"
      },
      "path": [
        "widths",
        "4"
      ]
    },
    "5": {
      "value": 5,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 5
      },
      "name": "Widths5",
      "attributes": {
        "category": "widths",
        "type": "5"
      },
      "path": [
        "widths",
        "5"
      ]
    },
    "6": {
      "value": 6,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 6
      },
      "name": "Widths6",
      "attributes": {
        "category": "widths",
        "type": "6"
      },
      "path": [
        "widths",
        "6"
      ]
    },
    "7": {
      "value": 7,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 7
      },
      "name": "Widths7",
      "attributes": {
        "category": "widths",
        "type": "7"
      },
      "path": [
        "widths",
        "7"
      ]
    },
    "8": {
      "value": 8,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 8
      },
      "name": "Widths8",
      "attributes": {
        "category": "widths",
        "type": "8"
      },
      "path": [
        "widths",
        "8"
      ]
    },
    "9": {
      "value": 9,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 9
      },
      "name": "Widths9",
      "attributes": {
        "category": "widths",
        "type": "9"
      },
      "path": [
        "widths",
        "9"
      ]
    },
    "10": {
      "value": 10,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 10
      },
      "name": "Widths10",
      "attributes": {
        "category": "widths",
        "type": "10"
      },
      "path": [
        "widths",
        "10"
      ]
    },
    "11": {
      "value": 11,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 11
      },
      "name": "Widths11",
      "attributes": {
        "category": "widths",
        "type": "11"
      },
      "path": [
        "widths",
        "11"
      ]
    },
    "12": {
      "value": 12,
      "filePath": "src/widths.json",
      "isSource": true,
      "original": {
        "value": 12
      },
      "name": "Widths12",
      "attributes": {
        "category": "widths",
        "type": "12"
      },
      "path": [
        "widths",
        "12"
      ]
    }
  },
  "z-index": {
    "sets": {
      "modal": {
        "value": 200,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 200
        },
        "name": "ZIndexSetsModal",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "modal"
        },
        "path": [
          "z-index",
          "sets",
          "modal"
        ]
      },
      "modalBG": {
        "value": 180,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 180
        },
        "name": "ZIndexSetsModalBg",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "modalBG"
        },
        "path": [
          "z-index",
          "sets",
          "modalBG"
        ]
      },
      "navFixed": {
        "value": 160,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 160
        },
        "name": "ZIndexSetsNavFixed",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "navFixed"
        },
        "path": [
          "z-index",
          "sets",
          "navFixed"
        ]
      },
      "tooltip": {
        "value": 140,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 140
        },
        "name": "ZIndexSetsTooltip",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "tooltip"
        },
        "path": [
          "z-index",
          "sets",
          "tooltip"
        ]
      },
      "popover": {
        "value": 120,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 120
        },
        "name": "ZIndexSetsPopover",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "popover"
        },
        "path": [
          "z-index",
          "sets",
          "popover"
        ]
      },
      "fab": {
        "value": 110,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 110
        },
        "name": "ZIndexSetsFab",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "fab"
        },
        "path": [
          "z-index",
          "sets",
          "fab"
        ]
      },
      "nav": {
        "value": 100,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 100
        },
        "name": "ZIndexSetsNav",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "nav"
        },
        "path": [
          "z-index",
          "sets",
          "nav"
        ]
      },
      "contentTop": {
        "value": 80,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 80
        },
        "name": "ZIndexSetsContentTop",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "contentTop"
        },
        "path": [
          "z-index",
          "sets",
          "contentTop"
        ]
      },
      "content": {
        "value": 60,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 60
        },
        "name": "ZIndexSetsContent",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "content"
        },
        "path": [
          "z-index",
          "sets",
          "content"
        ]
      },
      "contentBottom": {
        "value": 40,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 40
        },
        "name": "ZIndexSetsContentBottom",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "contentBottom"
        },
        "path": [
          "z-index",
          "sets",
          "contentBottom"
        ]
      },
      "backgroundTop": {
        "value": 20,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 20
        },
        "name": "ZIndexSetsBackgroundTop",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "backgroundTop"
        },
        "path": [
          "z-index",
          "sets",
          "backgroundTop"
        ]
      },
      "background": {
        "value": 0,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": 0
        },
        "name": "ZIndexSetsBackground",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "background"
        },
        "path": [
          "z-index",
          "sets",
          "background"
        ]
      },
      "backgroundBottom": {
        "value": -20,
        "filePath": "src/z-index.json",
        "isSource": true,
        "original": {
          "value": -20
        },
        "name": "ZIndexSetsBackgroundBottom",
        "attributes": {
          "category": "z-index",
          "type": "sets",
          "item": "backgroundBottom"
        },
        "path": [
          "z-index",
          "sets",
          "backgroundBottom"
        ]
      }
    }
  }
};
