const tocs = document.querySelectorAll('.c-bolt-toc');

if (tocs.length) {
  import(/* webpackChunkName: 'bolt-toc' */ './src/tableOfContents').then(
    ({ BoltTableOfContents }) => {
      tocs.forEach((el) => {
        const tocComponent = new BoltTableOfContents(el);
      });
    },
  );
}
