const teaser = document.querySelectorAll('.c-bolt-teaser');

if (teaser.length) {
  import(/* webpackChunkName: 'bolt-teaser' */ './src/teaser').then(
    ({ BoltTeaser }) => {
      teaser.forEach((el) => {
        const teaserComponent = new BoltTeaser(el);
      });
    },
  );
}
