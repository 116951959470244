import React, { useState } from 'react';
import schema from '@bolt/components-info-section/info-section.schema';
import { IconChevronDown } from '@bolt/react-icon';

function InfoSection({
  label,
  labelTag,
  detailsLink,
  open = schema.properties.open.default,
  collapsible = schema.properties.collapsible.default,
  children,
  className,
  ...otherProps
}) {
  const [isOpen, setIsOpen] = useState(open);

  const onClickHandler = (e) => {
    setIsOpen(!isOpen);
  };

  const LabelTag = schema.properties.label.properties.tag.enum.includes(
    labelTag,
  )
    ? labelTag
    : schema.properties.label.properties.tag.default;

  const attributes = {};
  const classes = ['c-bolt-info-section'];
  const userClasses = className ? className.split(' ') : [];

  if (userClasses.length) {
    classes.push(...userClasses);
  }

  // collapsible
  if (!collapsible) {
    classes.push(`c-bolt-info-section--no-collapse`);
  }

  attributes.className = classes.join(' ');

  return (
    <div {...attributes} {...otherProps}>
      {label && (
        <LabelTag className="c-bolt-info-section__headline">{label}</LabelTag>
      )}
      {collapsible && (
        <button
          className="c-bolt-info-section__headline c-bolt-info-section__headline--button"
          type="button"
          aria-expanded={isOpen}
          onClick={onClickHandler}>
          {label}
          <span className="c-bolt-info-section__headline-icon">
            <IconChevronDown />
          </span>
        </button>
      )}
      <div className="c-bolt-info-section__content">{children}</div>
      {detailsLink && (
        <div className="c-bolt-info-section__link">{detailsLink}</div>
      )}
    </div>
  );
}

export default InfoSection;
