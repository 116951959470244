const panels = document.querySelectorAll('.c-bolt-panel');

if (panels.length) {
  import(/* webpackChunkName: 'bolt-panel' */ './src/panel').then(
    ({ BoltPanel }) => {
      panels.forEach((el) => {
        const panelComponent = new BoltPanel(el);
      });
    },
  );
}
