import ReactPaginate from 'react-paginate';
import { IconChevronLeft, IconChevronRight } from '@bolt/react-icon';
import './index.scss';

function Pagination({
  align = 'center',
  nextLabel = 'Next',
  previousLabel = 'Previous',
  breakLabel = '...',
  breakAriaLabels = { forward: 'Jump forward', backward: 'Jump backward' },
  pageLabel = 'Page',
  pageLabelActiveMsg = 'is the current page',
  pageRangeDisplayed = 5,
  hideDisabledButtons = true,
  ...otherProps
}) {
  const alignOptions = ['center', 'start', 'end'];
  const baseItemClass = 'c-base-react-paginate__item';
  const baseButtonClasses = [
    'c-base-react-paginate__item-button',
    'e-bolt-button',
    'e-bolt-button--small',
    'e-bolt-button--border-radius-full',
  ];

  return (
    <ReactPaginate
      // Wrapper
      containerClassName={`c-base-react-paginate c-base-react-paginate--align-${
        alignOptions.includes(align) ? align : 'center'
      }`}
      // Items
      pageClassName={baseItemClass}
      breakClassName={baseItemClass}
      // Buttons
      pageLinkClassName={baseButtonClasses.join(' ')}
      breakLinkClassName={baseButtonClasses.join(' ')}
      previousLinkClassName={baseButtonClasses.join(' ')}
      nextLinkClassName={baseButtonClasses.join(' ')}
      // Button states
      activeLinkClassName={'c-base-react-paginate__item-button--active'}
      disabledLinkClassName={`c-base-react-paginate__item-button--disabled${
        hideDisabledButtons ? ' c-base-react-paginate__item-button--hidden' : ''
      }`}
      // Labels
      previousLabel={
        <>
          <IconChevronLeft size="small" /> {previousLabel}
        </>
      }
      nextLabel={
        <>
          {nextLabel} <IconChevronRight size="small" />
        </>
      }
      breakLabel={breakLabel}
      // Aria
      ariaLabelBuilder={(number, isCurrent) => {
        const title = `${pageLabel} ${number}`;
        return isCurrent ? `${title} ${pageLabelActiveMsg}` : title;
      }}
      breakAriaLabels={breakAriaLabels}
      // Defaults
      pageRangeDisplayed={pageRangeDisplayed}
      // ...
      {...otherProps}
    />
  );
}

export default Pagination;
