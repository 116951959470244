import { BoltAccordion } from './src/accordion';

// Initialize accordions on page load.
function initAccordion() {
  const accordions = document.querySelectorAll('.c-bolt-accordion');

  if (accordions.length) {
    accordions.forEach(el => {
      const accordionComponent = new BoltAccordion(el);
    });
  }
}

// Initialize accordions on page load.
initAccordion();

// @TODO: DEPRECATED.
// This eventListener will be removed in version 7.x.
// Initialize lp_accordions again whenever a new one is added to the page.
document.addEventListener('bolt:accordion-added', initAccordion);

// Add event listener for Accordion 2.
// This event is dispatched whenever a new Accordion Item 2 is added to an
// Accordion.
document.addEventListener('bolt:accordion-item-added', function (e) {
  if (e instanceof CustomEvent && e.detail.accordion) {
    // Update the Accordion.
    const accordionComponent = new BoltAccordion(e.detail.accordion);
  }
});
